import {TabContent} from '../TabContent'
import {columnDefs} from './ag-grid-constants'

export const OtherMapper = () => {
  return (
    <TabContent
      columnDefs={columnDefs}
      tab={'Other'}
      label={'Other Mapper'}
      api={'gcr_dim_other_mapper'}
      searchInput={'code, value'}
    />
  )
}
