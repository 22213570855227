import React from 'react'
import {Grid, Typography} from '@mui/material'
import {TextField, IconButton, Autocomplete, Label, FormTable} from 'finsys-webcomponent'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  AccManagedByAutocomplete,
  SubCategoryAutocomplete,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/constant'
import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'

/**
 * To validate acc-mgd_by and sub-category Update field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @param setFormErrorDetail
 * @returns {boolean}
 */
export const validateAccManagedBySubCatUpdateField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo,
  setFormErrorDetail
) => {
  const requiredFields = {
    mandatory: [{field: 'reason_for_change', label: 'Reason For Change'}],
    bankAccounts: [
      {field: 'bank_id', label: 'Bank Code'},
      // {field: 'obm_account_id', label: 'Account Number'},
      {field: 'account_number', label: 'Account Number'},
    ],
  }

  let isFormValid = true

  for (const [key, value] of Object.entries(requiredFields.mandatory)) {
    if (!formDetail[value.field] || formDetail[value.field].length === 0) {
      setFormArrayErrorValue(
        setBankAccountMaintenanceInfo,
        index,
        'form_details',
        value.field
      )
      isFormValid = false
    }
  }

  let isBankValid = true

  if (formDetail.bank_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'bank_details',
      'Please add at least 1 Bank Account Detail'
    )
    isFormValid = false
  } else {
    for (const bankIndex in formDetail.bank_details) {
      for (const [key, value] of Object.entries(requiredFields.bankAccounts)) {
        if (
          !formDetail.bank_details[bankIndex][value.field] ||
          formDetail.bank_details[bankIndex][value.field].length === 0
        ) {
          if (isBankValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'bank_details',
              'Please fill up all the required fields'
            )
            isBankValid = false
          }
          isFormValid = false
          setFormErrorDetail('bank_details', index, bankIndex, value.field)
        }
      }
    }
  }

  return isFormValid
}

/**
 * Acc Managed By and Sub Category Update form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param setArrayValue
 * @param disabled
 * @param reason
 * @param grouping
 * @param roles
 * @param OBMbanks
 * @param bankAccount
 * @param accountManagedBy
 * @param accountType
 * @returns {JSX.Element}
 * @constructor
 */
export const AccManagedBySubCatUpdate = ({
  detail,
  formIndex,
  setFormArrayValue,
  setArrayValue,
  disabled,
  // reason,
  OBMbanks,
  bankAccount,
  accountManagedBy,
  accountType,
  // formDetails,
}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label label={`Bank Account`} required={true} error={detail.error_bank_details} />
        <FormTable
          tableKey={'acc_mgd_by_sub_cat_update_bank_acc'}
          columns={[
            {
              name: 'Bank',
              width: '25%',
              field: 'bank_id',
              component: ({value, dataIndex, error}) => (
                <Autocomplete
                  options={OBMbanks}
                  getOptionLabel={(option) => option.text || ''}
                  handleOnChange={(value) => {
                    ;[
                      'obm_account_id',
                      'account_number',
                      'acc_managed_by',
                      'currency_code',
                      'account_type',
                    ].forEach((fieldName) => {
                      setArrayValue(formIndex, 'bank_details', dataIndex, fieldName, null)
                    })

                    setArrayValue(formIndex, 'bank_details', dataIndex, 'bank_id', value)
                  }}
                  value={value}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Bank Account Number',
              // field: 'obm_account_id',
              field: 'account_number',
              component: ({value, dataIndex, error, data}) => (
                <Autocomplete
                  options={
                    bankAccount[detail.bank_details[dataIndex].bank_id?.value]
                      ? [
                          {
                            acc_managed_by: null,
                            account_number: 'All Account',
                            currency_code: 'ALL',
                            account_type: null,
                            entity_id: 'ALL',
                            entity_name: null,
                          },
                          ...(bankAccount[
                            detail.bank_details[dataIndex].bank_id?.value
                          ] ?? []),
                        ]
                      : detail.bank_details[dataIndex].bank_id?.text === 'All Banks (ALL)'
                      ? [
                          {
                            acc_managed_by: null,
                            account_number: 'All Account',
                            currency_code: 'ALL',
                            account_type: null,
                            entity_id: null,
                            entity_name: null,
                          },
                        ]
                      : []
                  }
                  getOptionLabel={(option) => {
                    //   return option.account_number &&
                    //     option.account_number !== 'All Account'
                    //     ? `${option.account_number} - ${option.entity_code}`
                    //     : option.account_number === 'All Account'
                    //     ? option.account_number
                    //     : ''
                    // }}
                    return option.account_number === 'All Account'
                      ? option.account_number
                      : option.obm_account_id &&
                        option.account_number &&
                        option.entity_code != null
                      ? `${option.account_number} - ${option.entity_code}`
                      : option.obm_account_id &&
                        option.account_number &&
                        option.entity_code != null
                      ? option.account_number
                      : ''
                  }}
                  handleOnChange={(value) => {
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'obm_account_id',
                      value?.obm_account_id
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'account_number',
                      value?.account_number
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'entity_id',
                      value?.entity_id
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'entity_code',
                      value?.entity_code
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'currency_code',
                      value?.currency_code
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'acc_managed_by',
                      value?.acc_managed_by
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'account_type',
                      value?.account_type
                    )
                  }}
                  value={data}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Currency',
              width: '5%',
              field: 'currency_code',
            },
            {
              name: 'New Managed By (To Be)',
              field: 'acc_managed_by',
              width: '15%',
              component: ({value, dataIndex, error}) => {
                return (
                  <AccManagedByAutocomplete
                    options={accountManagedBy}
                    table={true}
                    value={value}
                    onChange={(value) =>
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'acc_managed_by',
                        value?.value
                      )
                    }
                    error={error}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              name: 'New Sub-Category (To Be)',
              field: 'account_type',
              width: '15%',
              component: ({value, dataIndex, error}) => {
                return (
                  <SubCategoryAutocomplete
                    options={accountType}
                    table={true}
                    value={value}
                    onChange={(value) =>
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'account_type',
                        value?.value
                      )
                    }
                    error={error}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              ...(!disabled && {
                name: '',
                field: '',
                width: '1%',
                component: ({dataIndex}) => {
                  return (
                    <IconButton
                      aria-label="delete"
                      handleOnClick={() => {
                        let newBankDetails = detail.bank_details
                        newBankDetails.splice(dataIndex, 1)
                        setFormArrayValue(
                          formIndex,
                          'form_details',
                          'bank_details',
                          newBankDetails
                        )
                      }}
                    >
                      <DeleteIcon style={{color: '#CE6B72'}} />
                    </IconButton>
                  )
                },
              }),
            },
          ]}
          data={detail.bank_details}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
          Details
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={'Reason For Change'}
          value={detail.reason_for_change ?? ''}
          onChange={(event) => {
            setFormArrayValue(
              formIndex,
              'form_details',
              'reason_for_change',
              event.target.value
            )
          }}
          multiline
          disabled={disabled}
          required
          error={!!detail.error_reason_for_change}
          helperText={detail.error_reason_for_change}
        />
      </Grid>
    </Grid>
  )
}
