import {Grid, Stack, Typography} from '@mui/material'
import {
  Accordion,
  Autocomplete,
  Button,
  DetailedLayout,
  Loading,
  TextField,
  blue,
} from 'finsys-webcomponent'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {validateForm} from '../Entity/Create'
import {
  fetchGCRBankCodeBankName,
  fetchGCRBankRating,
  submitGCRBankRating,
} from 'api/GCR/query/GCRQueries'
import useDataFetching from 'hoc/UseDataFetching'
import {getUserRole} from 'localstorage/UserRole'
import {HQ_ROLE_ID, dateTimeFormat} from '_helper/_constants'
import {SIDEBAR_DEFAULT, SIDEBAR_INFORMATION} from '..'
import {Info} from '@mui/icons-material'
import moment from 'moment'
import {queryClient} from 'hoc/ReactQuery'

export const EditGCRBankRating = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [formDetails, setFormDetails] = useState({
    bank_info: {code: '', description: ''},
    bank_code: '',
    region_code: '',
    one_yr_cds: null,
    avg_sum_of_rating: '',
    rating_type: '',
    avg_sum_of_rating_snp_equivalent: '',
    last_update_date: '',
    last_updated_by: '',
    error_bank_code: null,
    error_region_code: null,
  })
  const [sidebarDetails, setSidebarDetails] = useState(SIDEBAR_INFORMATION)

  const {data, loading} = useDataFetching(`gcr-bank-rating-${id}`, () =>
    fetchGCRBankRating(id)
  )

  const {data: bankInfo, loading: isBankInfoLoading} = useDataFetching(
    `gcr-bank-code-bank-name`,
    () => fetchGCRBankCodeBankName()
  )

  useEffect(() => {
    if (!data) {
      return
    }
    setFormDetails((prevState) => {
      return {
        ...prevState,
        bank_info: {code: data.bank_code, description: data.bank_fullname},
        bank_code: data.bank_code,
        region_code: data.region_code,
        one_yr_cds: data.one_yr_cds,
        avg_sum_of_rating: data.avg_sum_of_rating,
        rating_type: data.rating_type,
        avg_sum_of_rating_snp_equivalent: data.avg_sum_of_rating_snp_equivalent,
        last_update_date: data.last_update_date,
        last_updated_by: data.last_updated_by,
      }
    })
  }, [data])

  const editGCRBankRating = useMutation(() => submitGCRBankRating(formDetails, id), {
    onSuccess: (result) => {
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          type: 'success',
          message: 'Bank Rating successfully updated',
        }
      })
    },
    onError: (err) => {
      console.log(err)
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: err.data.status,
        }
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(`gcr-bank-rating-${id}`)
    },
  })

  const handleValidateForm = () => {
    const requiredFields = ['bank_code', 'region_code', 'one_yr_cds']

    if (
      isNaN(Number(formDetails.one_yr_cds)) &&
      !['N.A.', ''].includes(formDetails.one_yr_cds?.trim())
    ) {
      setFormDetails((prevState) => {
        return {
          ...prevState,
          error_one_yr_cds: "Enter a valid number or 'N.A.'",
        }
      })
      return
    }

    validateForm(formDetails, setFormDetails, requiredFields, editGCRBankRating)
  }

  const isAccessRestricted = () => {
    return getUserRole().role !== HQ_ROLE_ID
  }

  if (isBankInfoLoading) {
    return <Loading open={true} />
  }

  return (
    <DetailedLayout
      mainContent={
        <>
          <Accordion title="Details">
            <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  label={'Bank Info'}
                  value={formDetails.bank_info}
                  options={bankInfo}
                  getOptionLabel={(option) =>
                    option.code ? `${option.description} (${option.code})` : ''
                  }
                  handleOnChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      bank_info: value,
                      bank_code: value?.code,
                      error_bank_code: null,
                    })
                  }
                  textFieldProps={{
                    required: true,
                    error: !!formDetails.error_bank_code,
                    helperText: formDetails.error_bank_code,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Region Code'}
                  value={formDetails.region_code}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      region_code: value.target.value,
                      error_region_code: null,
                    })
                  }
                  required={true}
                  error={!!formDetails.error_region_code}
                  helperText={formDetails.error_region_code}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'One Year CDS'}
                  value={formDetails.one_yr_cds}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      one_yr_cds: value.target.value,
                      error_one_yr_cds: null,
                    })
                  }
                  required={true}
                  error={!!formDetails.error_one_yr_cds}
                  helperText={formDetails.error_one_yr_cds}
                  disabled={isAccessRestricted()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Average/ Summary of Rating '}
                  value={formDetails.avg_sum_of_rating}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      avg_sum_of_rating: value.target.value,
                    })
                  }
                  disabled={isAccessRestricted()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Rating Type'}
                  value={formDetails.rating_type}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      rating_type: value.target.value,
                    })
                  }
                  disabled={isAccessRestricted()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Average/Summary of Rating (S&P equivalent)'}
                  value={formDetails.avg_sum_of_rating_snp_equivalent}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      avg_sum_of_rating_snp_equivalent: value.target.value,
                    })
                  }
                  disabled={isAccessRestricted()}
                />
              </Grid>
            </Grid>
          </Accordion>
          {!isAccessRestricted() && (
            <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
              <Button label="Save" onClick={() => handleValidateForm()} />
            </Stack>
          )}
        </>
      }
      sideDrawerContent={
        <>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
                General
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Updated By"
                value={formDetails.last_updated_by}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Updated On"
                value={moment
                  .utc(formDetails.last_update_date)
                  .local()
                  .format(dateTimeFormat)}
                disabled
              />
            </Grid>
          </Grid>
        </>
      }
      collapseSideDrawer={[
        {
          icon: Info,
          label: 'Information',
          iconStyle: {
            color:
              sidebarDetails.type === SIDEBAR_INFORMATION.type
                ? blue.secondary
                : 'inherit',
          },
          onClick: () => setSidebarDetails(SIDEBAR_INFORMATION),
        },
      ]}
      defaultOnToggleSideMenu={(open) => {
        setSidebarDetails(open ? SIDEBAR_DEFAULT : SIDEBAR_INFORMATION)
      }}
    />
  )
}
