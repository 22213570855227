export const columnDefs = [
  {
    field: 'code',
    headerName: 'Code',
    cellRenderer: 'newTabLinkRenderer',
    cellRendererParams: {
      to: '/Gcr/MetadataGcr/Other',
      linkIdentification: 'oth_mapping_id',
      contentField: 'code',
    },
  },
  {
    field: 'value',
    headerName: 'Value',
  },
  {
    field: 'oth_mapping_type',
    headerName: 'Other Mapping Type',
  },
]
