import './index.scss'
import {Checkbox, Grid, Stack, TablePagination, Typography} from '@mui/material'
import {
  Accordion,
  Autocomplete,
  Button,
  Dialog,
  FormTable,
  Loading,
  TextField,
} from 'finsys-webcomponent'
import {Download as DownloadIcon, Sync as SyncIcon} from '@mui/icons-material'
import {useEffect, useRef, useState} from 'react'
import useDataFetching from 'hoc/UseDataFetching'
import {
  downloadMTR10Document,
  downloadMTR3Document,
  downloadGCR6Document,
  retrieveDataSyncHistory,
  retrieveHfmLastSync,
  retrieveKyribaLastSync,
  syncKyribaData,
  retrieveEntityRegions,
  retrieveGroupPIC,
} from 'api/GCR/query/GCRQueries'
import moment from 'moment'
import {dateTimeFormat} from '_helper/_constants'
import {useMutation} from 'react-query'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {queryClient} from 'hoc/ReactQuery'
import MultipleSelect from 'components/GCR/Summary/ReportDownload/MultipleSelect'
import {useAuth} from 'hoc/AuthContext'

export function ReportsHub() {
  const {user} = useAuth()
  const secondTextFieldRef = useRef(null)
  const dialogSecondTextFieldRef = useRef(null)
  const {setSnackBar} = useSnackBar()
  const [throttle, setThrottle] = useState(null)
  const [reportLastSynced, setReportLastSynced] = useState({
    kyriba_sync_date: '',
    kyriba_file_date: '',
    hfm: '',
  })
  const [filterDisplay, setFilterDisplay] = useState({
    checked: false,
    year: '',
    month: '',
  })
  const [serverPaginatedResults, setServerPaginatedResults] = useState({
    last_update_date: null,
    page: 1,
    rowsPerPage: 10,
    totalCount: 0,
    year: '',
    month: '',
    results: [],
  })
  const [dataSyncDialog, setDataSyncDialog] = useState({
    open: false,
    year: '',
    month: '',
    error_year: '',
    error_month: '',
  })
  const [mtrDownloadDialog, setMtrDownloadDialog] = useState({
    open: false,
    document: {label: '', value: ''},
    year: '',
    month: '',
    region: [],
    error_document: '',
    error_year: '',
    error_month: '',
    error_region: '',
  })

  const {data: hqMtrPic, loading: isHqMtrPic} = useDataFetching('hq-mtr-pic', () =>
    retrieveGroupPIC('HQ_MTR')
  )

  const {data: entityRegions, loading: isEntityRegionsLoading} = useDataFetching(
    'entity-regions',
    () => retrieveEntityRegions()
  )

  const {data: kyribaLastSync, loading: isKyribaLastSyncLoading} = useDataFetching(
    'kyriba-last-sync',
    () => retrieveKyribaLastSync()
  )

  const {data: hfmLastSync, loading: isHfmLastSyncLoading} = useDataFetching(
    'hfm-last-sync',
    () => retrieveHfmLastSync()
  )

  const {data: dataSyncHistory, loading: isDataSyncHistoryLoading} = useDataFetching(
    [
      {
        page: serverPaginatedResults.page,
        rowsPerPage: serverPaginatedResults.rowsPerPage,
        year: serverPaginatedResults.year,
        month: serverPaginatedResults.month,
      },
    ],
    () =>
      retrieveDataSyncHistory(
        serverPaginatedResults.rowsPerPage,
        serverPaginatedResults.page,
        serverPaginatedResults.year,
        serverPaginatedResults.month
      )
  )

  useEffect(() => {
    if (!dataSyncHistory) {
      return
    }
    setServerPaginatedResults((prevState) => {
      return {
        ...prevState,
        totalCount: dataSyncHistory.count,
        results: dataSyncHistory.results,
      }
    })
  }, [dataSyncHistory])

  useEffect(() => {
    if (!kyribaLastSync) {
      return
    }

    let kyribaSyncDate = moment
      .utc(kyribaLastSync.last_sync_date)
      .local()
      .format(dateTimeFormat)

    let kyribaFileDate = moment
      .utc(kyribaLastSync.kyriba_file_date)
      .local()
      .format('MMM Do YYYY')

    setReportLastSynced({
      ...reportLastSynced,
      kyriba_sync_date: kyribaSyncDate,
      kyriba_file_date: kyribaFileDate,
    })
  }, [kyribaLastSync])

  useEffect(() => {
    if (!hfmLastSync) {
      return
    }

    let hfmSyncDate = moment
      .utc(hfmLastSync.last_run_datetime)
      .format('MMM Do YYYY, H:mm [GMT]+0800')
    setReportLastSynced({...reportLastSynced, hfm: hfmSyncDate})
  }, [hfmLastSync])

  const {isLoading: isDataSyncLoading, ...handleDataSync} = useMutation(
    () => syncKyribaData(dataSyncDialog.year, dataSyncDialog.month),
    {
      onSuccess: (result) => {
        setSnackBar((prevState) => {
          return {
            ...prevState,
            open: true,
            type: 'success',
            message: 'Kyriba data successfully synced',
          }
        })
      },
      onError: (error) => {
        console.log(error)
        setSnackBar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: `Failed to sync data`,
          }
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries('kyriba-last-sync')
        queryClient.invalidateQueries([
          {
            page: serverPaginatedResults.page,
            rowsPerPage: serverPaginatedResults.rowsPerPage,
            year: serverPaginatedResults.year,
            month: serverPaginatedResults.month,
          },
        ])
      },
    }
  )

  const {isLoading: isMtr3DownloadLoading, ...handleDownloadMtr3} = useMutation(
    () =>
      downloadMTR3Document(
        mtrDownloadDialog.year,
        mtrDownloadDialog.month,
        mtrDownloadDialog.region
      ),
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          type: 'success',
          message: 'MTR3 report downloaded successfully',
        })
      },
      onError: (error) => {
        console.log(error)
        setSnackBar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: `Failed to download MTR3 report`,
          }
        })
      },
    }
  )

  const {isLoading: isGcr6DownloadLoading, ...handleDownloadGcr6} = useMutation(
    () => downloadGCR6Document(mtrDownloadDialog.year, mtrDownloadDialog.month),
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          type: 'success',
          message: 'GCR6 report downloaded successfully',
        })
      },
      onError: (error) => {
        console.log(error)
        setSnackBar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: `Failed to download GCR6 report`,
          }
        })
      },
    }
  )

  const {isLoading: isMtr10DownloadLoading, ...handleDownloadMtr10} = useMutation(
    () =>
      downloadMTR10Document(
        mtrDownloadDialog.year,
        mtrDownloadDialog.month,
        mtrDownloadDialog.region
      ),
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          type: 'success',
          message: 'MTR10 report downloaded successfully',
        })
      },
      onError: (error) => {
        console.log(error)
        setSnackBar((prevState) => {
          return {
            ...prevState,
            open: true,
            message: `Failed to download MTR10 report`,
          }
        })
      },
    }
  )

  const handleMtrReportDownload = () => {
    const reportType = mtrDownloadDialog.document?.value

    const isDataValid = validateReportDownload(reportType)
    if (!isDataValid) return

    setMtrDownloadDialog({
      open: false,
      document: '',
      year: '',
      month: '',
      region: [],
    })

    switch (reportType) {
      case 'mtr_wd3':
        return handleDownloadMtr3.mutate()
      case 'gcr_wd6':
        return handleDownloadGcr6.mutate()
      case 'mtr_wd10':
        return handleDownloadMtr10.mutate()
    }
  }

  const validateReportDownload = (reportType) => {
    let isDataValid = true

    const requiredFields = ['document', 'year', 'month']
    if (['mtr_wd3', 'mtr_wd10'].includes(reportType)) {
      requiredFields.push('region')
    }

    requiredFields.forEach((field) => {
      if (
        mtrDownloadDialog[field]?.value === '' ||
        mtrDownloadDialog[field]?.length === 0 ||
        !mtrDownloadDialog[field]
      ) {
        setMtrDownloadDialog((prevState) => {
          return {...prevState, [`error_${field}`]: 'Field cannot be blank'}
        })
        isDataValid = false
      }
    })

    return isDataValid
  }

  const validateDataSync = () => {
    let isDataValid = true

    const requiredFields = ['year', 'month']
    requiredFields.forEach((field) => {
      if (!dataSyncDialog[field]) {
        setDataSyncDialog((prevState) => {
          return {...prevState, [`error_${field}`]: 'Field cannot be blank'}
        })
        isDataValid = false
      }
    })
    if (!isDataValid) return

    setDataSyncDialog((prevState) => {
      return {
        ...dataSyncDialog,
        month: prevState.month.length < 2 ? '0' + prevState.month : prevState.month,
        open: true,
      }
    })
  }

  if (
    isHqMtrPic ||
    isKyribaLastSyncLoading ||
    isHfmLastSyncLoading ||
    isDataSyncLoading ||
    isMtr3DownloadLoading ||
    isGcr6DownloadLoading ||
    isMtr10DownloadLoading ||
    isEntityRegionsLoading
  ) {
    return <Loading open={true} />
  }

  return (
    <>
      {dataSyncDialog.open && (
        <Dialog
          title="Kyriba Data Sync"
          type="error"
          disableBackdropClick
          content={[
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography>Confirm to sync data for period: </Typography>
                <Typography>
                  {dataSyncDialog.year}/{dataSyncDialog.month}
                </Typography>
              </Grid>
            </Grid>,
          ]}
          buttons={[
            {
              label: 'Confirm',
              type: 'primary',
              onClick: () => {
                setDataSyncDialog({
                  open: false,
                  year: '',
                  month: '',
                  error_year: '',
                  error_month: '',
                })
                handleDataSync.mutate()
              },
            },
            {
              label: 'Cancel',
              type: 'text',
              onClick: () => {
                setDataSyncDialog({
                  open: false,
                  year: '',
                  month: '',
                  error_year: '',
                  error_month: '',
                })
              },
            },
          ]}
        />
      )}
      {mtrDownloadDialog.open && (
        <Dialog
          title="MTR Report Download"
          type="info"
          disableBackdropClick
          content={[
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  label="Select Report Type"
                  options={[
                    {label: 'MTR WD3', value: 'mtr_wd3'},
                    {label: 'GCR WD6', value: 'gcr_wd6'},
                    {label: 'MTR WD10', value: 'mtr_wd10'},
                  ]}
                  getOptionLabel={(option) => {
                    return option.label ?? option
                  }}
                  value={mtrDownloadDialog.document}
                  handleOnChange={(value) => {
                    setMtrDownloadDialog({
                      ...mtrDownloadDialog,
                      document: value,
                      error_document: '',
                    })
                  }}
                  textFieldProps={{
                    required: true,
                    error: !!mtrDownloadDialog.error_document,
                    helperText: mtrDownloadDialog.error_document,
                  }}
                />
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={6}>
                  <TextField
                    label="Period"
                    placeholder="YYYY"
                    inputProps={{maxLength: 4}}
                    onChange={(event) => {
                      const input = event.target.value
                      if (/^\d{0,4}$/.test(input)) {
                        setMtrDownloadDialog({
                          ...mtrDownloadDialog,
                          year: input,
                          error_year: '',
                        })
                      }
                      if (event.target.value.length >= 4) {
                        secondTextFieldRef.current?.focus()
                      }
                    }}
                    value={mtrDownloadDialog.year}
                    required
                    error={!!mtrDownloadDialog.error_year}
                    helperText={mtrDownloadDialog.error_year}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    label=""
                    value="/"
                    inputProps={{
                      style: {textAlign: 'center'},
                      tabIndex: -1,
                    }}
                    sx={{
                      pointerEvents: 'none',
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label=""
                    placeholder="MM"
                    inputRef={secondTextFieldRef}
                    inputProps={{maxLength: 2}}
                    onChange={(event) => {
                      const input = event.target.value
                      if (/^\d{0,2}$/.test(input) && input < 13) {
                        setMtrDownloadDialog({
                          ...mtrDownloadDialog,
                          month: input,
                          error_month: '',
                        })
                      }
                    }}
                    value={mtrDownloadDialog.month}
                    error={!!mtrDownloadDialog.error_month}
                    helperText={mtrDownloadDialog.error_month}
                  />
                </Grid>
              </Grid>
              {['mtr_wd3', 'mtr_wd10'].includes(mtrDownloadDialog.document?.value) && (
                <Grid item xs={6}>
                  <MultipleSelect
                    isAll={true}
                    label="Select Region"
                    isOptionEqualToValue={(option, value) => {
                      return option?.region_mapping === value?.region_mapping
                    }}
                    selections={[{region_mapping: 'All'}, ...entityRegions]}
                    getOptionLabel={(option) => {
                      return `${option.region_mapping}`
                    }}
                    value={mtrDownloadDialog.region}
                    onChange={(event, value) => {
                      if (value[value.length - 1]?.region_mapping === 'All') {
                        setMtrDownloadDialog((prevState) => {
                          return {
                            ...prevState,
                            error_region: '',
                            region:
                              prevState.region.length === entityRegions.length
                                ? []
                                : entityRegions,
                          }
                        })
                      } else {
                        setMtrDownloadDialog((prevState) => {
                          return {...prevState, region: value, error_region: ''}
                        })
                      }
                    }}
                    limitTags={1}
                    textFieldProps={{
                      error: !!mtrDownloadDialog.error_region,
                      helperText: mtrDownloadDialog.error_region,
                    }}
                  />
                </Grid>
              )}
            </Grid>,
          ]}
          buttons={[
            {
              label: 'Download',
              type: 'primary',
              onClick: () => {
                handleMtrReportDownload()
              },
            },
            {
              label: 'Close',
              type: 'text',
              onClick: () => {
                setMtrDownloadDialog({
                  open: false,
                  document: '',
                  year: '',
                  month: '',
                  region: [],
                })
              },
            },
          ]}
        />
      )}
      <Accordion title="Report Generation">
        <Grid container rowSpacing={4}>
          <Grid item container xs={12} rowSpacing={1}>
            <Grid item xs={12}>
              <Typography variant="caption">Download Report Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'}>
                <Button
                  label="Download Report"
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    setMtrDownloadDialog({...mtrDownloadDialog, open: true})
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <FormTable
                className={'reportGeneration'}
                columns={[
                  {field: 'system', name: 'System', width: '20%'},
                  {
                    field: 'file_date',
                    name: 'Kyriba File Date',
                    width: '40%',
                  },
                  {
                    field: 'last_sync_date',
                    name: 'Synced Date',
                  },
                ]}
                data={[
                  {
                    system: 'Kyriba',
                    last_sync_date: reportLastSynced.kyriba_sync_date,
                    file_date: reportLastSynced.kyriba_file_date,
                  },
                  {system: 'HFM', last_sync_date: reportLastSynced.hfm, file_date: '-'},
                ]}
                noDataLabel="No Data"
              />
            </Grid>
          </Grid>
          {hqMtrPic.some((pic) => pic.user_email === user.email) && (
            <Grid item container xs={12} rowSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption">Sync Report Information</Typography>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={2}>
                  <TextField
                    label="Select sync period"
                    placeholder="YYYY"
                    inputProps={{maxLength: 4}}
                    onChange={(event) => {
                      const input = event.target.value
                      if (/^\d{0,4}$/.test(input)) {
                        setDataSyncDialog({
                          ...dataSyncDialog,
                          year: input,
                          error_year: '',
                        })
                      }
                      if (event.target.value.length >= 4) {
                        dialogSecondTextFieldRef.current?.focus()
                      }
                    }}
                    value={dataSyncDialog.year}
                    required
                    error={!!dataSyncDialog?.error_year}
                    helperText={dataSyncDialog?.error_year}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    label=""
                    value="/"
                    inputProps={{
                      style: {textAlign: 'center'},
                      tabIndex: -1,
                    }}
                    sx={{
                      pointerEvents: 'none',
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label=""
                    placeholder="MM"
                    inputRef={dialogSecondTextFieldRef}
                    inputProps={{maxLength: 2}}
                    onChange={(event) => {
                      const input = event.target.value
                      if (/^\d{0,2}$/.test(input) && input < 13) {
                        setDataSyncDialog({
                          ...dataSyncDialog,
                          month: input,
                          error_month: '',
                        })
                      }
                    }}
                    value={dataSyncDialog.month}
                    error={!!dataSyncDialog?.error_month}
                    helperText={dataSyncDialog?.error_month}
                  />
                </Grid>
                <Grid container item xs={4} paddingLeft={4} alignItems={'flex-end'}>
                  <Button
                    label="Sync Kyriba"
                    startIcon={
                      <SyncIcon
                        className={isDataSyncLoading && 'spin'}
                        sx={{fontSize: 24}}
                      />
                    }
                    onClick={() => validateDataSync()}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Accordion>
      <Accordion title="Data Sync History" expanded={false}>
        <Grid container>
          <Grid item container xs={12} sm={8} md={6}>
            <Grid item xs={4} sm={3} xl={2}>
              <Stack direction={'row'} alignItems={'center'}>
                <Checkbox
                  size="small"
                  onChange={(event) => {
                    setFilterDisplay({year: '', month: '', checked: event.target.checked})
                    setServerPaginatedResults({
                      ...serverPaginatedResults,
                      year: '',
                      month: '',
                    })
                  }}
                  value={filterDisplay.checked}
                />
                <Typography variant="caption">Filter by period</Typography>
              </Stack>
            </Grid>
            {filterDisplay.checked && (
              <Grid item container xs={6} sm={5} md={4} lg={3}>
                <Grid item xs={3}>
                  <TextField
                    label=""
                    placeholder="YYYY"
                    inputProps={{maxLength: 4}}
                    onChange={(event) => {
                      const input = event.target.value
                      if (/^\d{0,4}$/.test(input)) {
                        setFilterDisplay({...filterDisplay, year: input})
                        clearTimeout(throttle)
                        setThrottle(
                          setTimeout(
                            () =>
                              setServerPaginatedResults({
                                ...serverPaginatedResults,
                                year: input,
                              }),
                            500
                          )
                        )
                      }
                    }}
                    value={filterDisplay.year}
                    sx={{
                      '& .MuiInputBase-root': {
                        marginTop: '0px',
                        fontSize: '14px',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    label=""
                    value="/"
                    inputProps={{
                      style: {textAlign: 'center'},
                      tabIndex: -1,
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        marginTop: '0px',
                        fontSize: '14px',
                      },
                      pointerEvents: 'none',
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label=""
                    placeholder="MM"
                    inputProps={{maxLength: 2}}
                    onChange={(event) => {
                      const input = event.target.value
                      if (/^\d{0,2}$/.test(input) && input < 13) {
                        setFilterDisplay({...filterDisplay, month: input})
                        clearTimeout(throttle)
                        setThrottle(
                          setTimeout(
                            () =>
                              setServerPaginatedResults({
                                ...serverPaginatedResults,
                                month: input,
                              }),
                            500
                          )
                        )
                      }
                    }}
                    value={filterDisplay.month}
                    sx={{
                      '& .MuiInputBase-root': {
                        marginTop: '0px',
                        fontSize: '14px',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {isDataSyncHistoryLoading && (
              <span style={{position: 'absolute', width: '95%'}}>
                <Loading open={true} fullScreen={false} />
              </span>
            )}
            <FormTable
              style={isDataSyncHistoryLoading ? {opacity: '0.2'} : null}
              columns={[
                {field: 'period', name: 'Sync Period', width: '20%'},
                {
                  field: 'kyriba_file_date',
                  name: 'Kyriba File Date',
                  width: '30%',
                  component: ({data}) =>
                    moment.utc(data.kyriba_file_date).local().format('MMM Do YYYY'),
                },
                {
                  field: 'last_sync_date',
                  name: 'Synced Date',
                  width: '30%',
                  component: ({data}) =>
                    moment.utc(data.last_sync_date).local().format(dateTimeFormat),
                },
                {field: 'last_sync_by', name: 'Synced By'},
              ]}
              data={serverPaginatedResults.results}
              noDataLabel="No History"
            />
          </Grid>
          <Grid item container xs={12} justifyContent={'flex-end'}>
            <TablePagination
              showFirstButton
              showLastButton
              page={serverPaginatedResults.page - 1}
              rowsPerPage={serverPaginatedResults.rowsPerPage}
              count={serverPaginatedResults.totalCount}
              onPageChange={(event, newPage) => {
                setServerPaginatedResults({...serverPaginatedResults, page: newPage + 1})
              }}
              onRowsPerPageChange={(event) => {
                setServerPaginatedResults({
                  ...serverPaginatedResults,
                  rowsPerPage: parseInt(event.target.value, 10),
                  page: 1,
                })
              }}
              sx={{borderBottom: 0}}
            />
          </Grid>
        </Grid>
      </Accordion>
    </>
  )
}
