import React from 'react'
import {Grid, Typography} from '@mui/material'
import {TextField, Autocomplete, Label, FormTable} from 'finsys-webcomponent'

import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'

/**
 * To validate Bank Account Number Update field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @param setFormErrorDetail
 * @returns {boolean}
 */
export const validateBankAccountNumberUpdateField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo,
  setFormErrorDetail
) => {
  const requiredFields = {
    mandatory: [
      {field: 'new_bank_account_number', label: 'New Bank Account Number'},
      {field: 'reason_for_change', label: 'Reason For Change'},
    ],
    bankAccounts: [
      {field: 'bank_id', label: 'Bank Code'},
      {field: 'obm_account_id', label: 'Account Number'},
      {field: 'acc_managed_by', label: 'Account Managed By'},
    ],
  }

  let isFormValid = true

  for (const [key, value] of Object.entries(requiredFields.mandatory)) {
    if (!formDetail[value.field] || formDetail[value.field].length === 0) {
      setFormArrayErrorValue(
        setBankAccountMaintenanceInfo,
        index,
        'form_details',
        value.field
      )
      isFormValid = false
    }
  }

  let isBankValid = true

  if (formDetail.bank_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'bank_details',
      'Please add at least 1 Bank Acccount Detail'
    )
    isFormValid = false
  } else {
    for (const bankIndex in formDetail.bank_details) {
      for (const [key, value] of Object.entries(requiredFields.bankAccounts)) {
        if (
          !formDetail.bank_details[bankIndex][value.field] ||
          formDetail.bank_details[bankIndex][value.field].length === 0
        ) {
          if (isBankValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'bank_details',
              'Please fill up all the required fields'
            )
            isBankValid = false
          }
          isFormValid = false
          setFormErrorDetail('bank_details', index, bankIndex, value.field)
        }
      }
    }
  }

  return isFormValid
}

/**
 * Bank Account Number update form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param setArrayValue
 * @param setArrayValue
 * @param disabled
 * @param OBMbanks
 * @param accountMangedBy
 * @param bankAccount
 * @returns {JSX.Element}
 * @constructor
 */
export const BankAccountNumberUpdate = ({
  detail,
  formIndex,
  setFormArrayValue,
  setArrayValue,
  disabled,
  OBMbanks,
  bankAccount,
  accountMangedBy,
}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label label={`Bank Account`} required={true} error={detail.error_bank_details} />
        <FormTable
          tableKey={'update_bank_account_number'}
          columns={[
            {
              name: 'Bank',
              field: 'bank_id',
              width: '30%',
              component: ({value, dataIndex, error}) => {
                return (
                  <Autocomplete
                    options={OBMbanks}
                    getOptionLabel={(option) => option.text || ''}
                    handleOnChange={(value) => {
                      ;['obm_account_id', 'acc_managed_by', 'currency_code'].forEach(
                        (fieldName) => {
                          setArrayValue(
                            formIndex,
                            'bank_details',
                            dataIndex,
                            fieldName,
                            null
                          )
                        }
                      )

                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'bank_id',
                        value
                      )
                    }}
                    value={value}
                    textFieldProps={{
                      error: !!error,
                      sx: {
                        '& label': {display: 'none'},
                        '& .MuiInput-root': {mt: 0},
                      },
                    }}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              name: 'Bank Account Number',
              field: 'obm_account_id',
              width: '20%',
              component: ({value, dataIndex, error, data}) => {
                return (
                  <Autocomplete
                    options={
                      bankAccount[detail.bank_details[dataIndex].bank_id?.value]
                        ? [
                            {},
                            ...(bankAccount[
                              detail.bank_details[dataIndex].bank_id?.value
                            ] ?? []),
                          ]
                        : []
                    }
                    getOptionLabel={(option) => {
                      return option.obm_account_id &&
                        option.account_number &&
                        option.entity_code != null
                        ? `${option.account_number} - ${option.entity_code}`
                        : option.obm_account_id &&
                          option.account_number &&
                          option.entity_code != null
                        ? option.account_number
                        : ''
                    }}
                    handleOnChange={(value) => {
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'obm_account_id',
                        value?.obm_account_id
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'account_number',
                        value?.account_number
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'entity_code',
                        value?.entity_code
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'entity_id',
                        value?.entity_id
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'currency_code',
                        value?.currency_code
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'account_type',
                        value?.account_type
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'acc_managed_by',
                        value?.acc_managed_by
                      )
                    }}
                    value={data}
                    textFieldProps={{
                      error: !!error,
                      sx: {
                        '& label': {display: 'none'},
                        '& .MuiInput-root': {mt: 0},
                      },
                    }}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              name: 'Account Type',
              width: '5%',
              field: 'account_type',
            },
            {
              name: 'Currency',
              width: '5%',
              field: 'currency_code',
            },
            {
              name: 'Managed By',
              field: 'acc_managed_by',
              width: '12%',
              dependentValue: 'obm_account_id',
              component: ({value, dataIndex, data, error}) => {
                const selectManagedBy = data?.account_number === 'All Account'

                if (selectManagedBy) {
                  return (
                    <Autocomplete
                      options={accountMangedBy}
                      getOptionLabel={(option) => option.text ?? option}
                      handleOnChange={(value) => {
                        setArrayValue(
                          formIndex,
                          'bank_details',
                          dataIndex,
                          'acc_managed_by',
                          value.value
                        )
                      }}
                      value={value ?? ''}
                      textFieldProps={{
                        error: !!error,
                        sx: {
                          '& label': {display: 'none'},
                          '& .MuiInput-root': {mt: 0},
                        },
                      }}
                      disabled={!selectManagedBy || disabled}
                    />
                  )
                } else {
                  return value ?? ''
                }
              },
            },
          ]}
          data={detail.bank_details}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
          New Bank Account Number Info
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={'New Bank Account Number'}
          value={detail.new_bank_account_number ?? ''}
          onChange={(event) => {
            setFormArrayValue(
              formIndex,
              'form_details',
              'new_bank_account_number',
              event.target.value
            )
          }}
          disabled={disabled}
          required
          error={!!detail.error_new_bank_account_number}
          helperText={detail.error_new_bank_account_number}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={'Reason For Change'}
          value={detail.reason_for_change ?? ''}
          onChange={(event) => {
            setFormArrayValue(
              formIndex,
              'form_details',
              'reason_for_change',
              event.target.value
            )
          }}
          multiline
          disabled={disabled}
          required
          error={!!detail.error_reason_for_change}
          helperText={detail.error_reason_for_change}
        />
      </Grid>
    </Grid>
  )
}
