import {Grid, Stack} from '@mui/material'
import {submitGCREntity} from 'api/GCR/query/GCRQueries'
import {Accordion, Button, TextField} from 'finsys-webcomponent'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {useState} from 'react'
import {useMutation} from 'react-query'
import {useNavigate} from 'react-router-dom'

export const validateForm = (formDetails, setFormDetails, requiredFields, mutateFn) => {
  let areRequiredFieldValid = true
  requiredFields.forEach((requiredField) => {
    if (!formDetails[requiredField]?.trim()) {
      setFormDetails((prevState) => {
        return {
          ...prevState,
          [`error_${requiredField}`]: 'This is a required field',
        }
      })
      areRequiredFieldValid = false
    }
  })
  if (areRequiredFieldValid) {
    mutateFn.mutate()
  }
}

export const CreateGCREntity = () => {
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [formDetails, setFormDetails] = useState({
    code: '',
    description: '',
    region_mapping: '',
    country_for_gct_grouping: '',
    hq_tag_for_gcr: '',
    error_code: null,
    error_description: null,
    error_region_mapping: null,
  })

  const createGCREntity = useMutation(() => submitGCREntity(formDetails), {
    onSuccess: (result) => {
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          type: 'success',
          message: 'Entity successfully created',
        }
      })
      navigate(`/Gcr/MetadataGcr/Entity/${result.entity_id}/`)
    },
    onError: (err) => {
      console.log(err)
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
        }
      })
    },
  })

  const handleValidateForm = () => {
    const requiredFields = ['code', 'description']
    validateForm(formDetails, setFormDetails, requiredFields, createGCREntity)
  }

  return (
    <>
      <Accordion title="Details">
        <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Code'}
              value={formDetails.code}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  code: value.target.value,
                  error_code: null,
                })
              }
              required
              error={!!formDetails.error_code}
              helperText={formDetails.error_code}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Description'}
              value={formDetails.description}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  description: value.target.value,
                  error_description: null,
                })
              }
              required
              error={!!formDetails.error_description}
              helperText={formDetails.error_description}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'MTR Region'}
              value={formDetails.region_mapping}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  country: value.target.value,
                  error_region_mapping: null,
                })
              }
              error={!!formDetails.error_region_mapping}
              helperText={formDetails.error_region_mapping}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'GCR Region'}
              value={formDetails.country_for_gct_grouping}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  country_for_gct_grouping: value.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'GCR HQ Tag'}
              value={formDetails.hq_tag_for_gcr}
              onChange={(value) =>
                setFormDetails({...formDetails, hq_tag_for_gcr: value.target.value})
              }
            />
          </Grid>
        </Grid>
      </Accordion>
      <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
        <Button label="Cancel" type="text" onClick={() => navigate('/Gcr/MetadataGcr')} />
        <Button label="Create" onClick={() => handleValidateForm()} />
      </Stack>
    </>
  )
}
