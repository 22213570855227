import {Grid, Stack, Typography} from '@mui/material'
import {Info} from '@mui/icons-material'
import {fetchGCREntity, submitGCREntity} from 'api/GCR/query/GCRQueries'
import {
  Accordion,
  Button,
  DetailedLayout,
  TextField,
  blue,
  Autocomplete,
} from 'finsys-webcomponent'
import {useSnackBar} from 'hoc/SnackbarHandler'
import useDataFetching from 'hoc/UseDataFetching'
import {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {validateForm} from './Create'
import {getUserRole} from 'localstorage/UserRole'
import {HQ_GCT_ROLE_ID, HQ_ROLE_ID, dateTimeFormat} from '_helper/_constants'
import moment from 'moment'
import {queryClient} from 'hoc/ReactQuery'
import {SIDEBAR_DEFAULT, SIDEBAR_INFORMATION} from '..'

export const EditGCREntity = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [formDetails, setFormDetails] = useState({
    code: '',
    description: '',
    region_mapping: '',
    country_for_gct_grouping: '',
    hq_tag_for_gcr: '',
    last_update_date: '',
    last_updated_by: '',
    error_code: null,
    error_description: null,
    error_region_mapping: null,
  })
  const [sidebarDetails, setSidebarDetails] = useState(SIDEBAR_INFORMATION)

  const {data, loading} = useDataFetching(`gcr-entity-${id}`, () => fetchGCREntity(id))

  useEffect(() => {
    if (!data) {
      return
    }
    setFormDetails((prevState) => {
      return {
        ...prevState,
        code: data.code,
        description: data.description,
        region_mapping: data.region_mapping,
        country_for_gct_grouping: data.country_for_gct_grouping,
        hq_tag_for_gcr: data.hq_tag_for_gcr,
        last_update_date: data.last_update_date,
        last_updated_by: data.last_updated_by,
      }
    })
  }, [data])

  const editGCREntity = useMutation(() => submitGCREntity(formDetails, id), {
    onSuccess: (result) => {
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          type: 'success',
          message: 'Entity successfully updated',
        }
      })
    },
    onError: (err) => {
      console.log(err)
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
        }
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(`gcr-entity-${id}`)
    },
  })

  const handleValidateForm = () => {
    const requiredFields = ['code', 'description']
    validateForm(formDetails, setFormDetails, requiredFields, editGCREntity)
  }

  const isTresRestricted = () => {
    return getUserRole().role !== HQ_ROLE_ID
  }

  const isGctRestricted = () => {
    return getUserRole().role !== HQ_GCT_ROLE_ID
  }

  return (
    <DetailedLayout
      mainContent={
        <>
          <Accordion title="Details">
            <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <TextField label={'Code'} value={formDetails.code} disabled={true} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Description'}
                  value={formDetails.description}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      description: value.target.value,
                      error_description: null,
                    })
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'MTR Region'}
                  value={formDetails.region_mapping}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      region_mapping: value.target.value,
                      error_region_mapping: null,
                    })
                  }
                  error={!!formDetails.error_region_mapping}
                  helperText={formDetails.error_region_mapping}
                  disabled={isTresRestricted()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'GCR Region'}
                  value={formDetails.country_for_gct_grouping}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      country_for_gct_grouping: value.target.value,
                    })
                  }
                  disabled={isGctRestricted()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label={'Select GCR HQ Tag'}
                  options={[
                    {label: 'Yes', value: 'Yes'},
                    {label: 'No', value: 'No'},
                  ]}
                  getOptionLabel={(option) => {
                    return option.label ?? option
                  }}
                  value={formDetails.hq_tag_for_gcr}
                  handleOnChange={(value) =>
                    setFormDetails({...formDetails, hq_tag_for_gcr: value?.value ?? null})
                  }
                  disabled={isGctRestricted()}
                />
              </Grid>
            </Grid>
          </Accordion>
          {(!isTresRestricted() || !isGctRestricted()) && (
            <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
              <Button label="Save" onClick={() => handleValidateForm()} />
            </Stack>
          )}
        </>
      }
      sideDrawerContent={
        <>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
                General
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Updated By"
                value={formDetails.last_updated_by}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Updated On"
                value={moment
                  .utc(formDetails.last_update_date)
                  .local()
                  .format(dateTimeFormat)}
                disabled
              />
            </Grid>
          </Grid>
        </>
      }
      collapseSideDrawer={[
        {
          icon: Info,
          label: 'Information',
          iconStyle: {
            color:
              sidebarDetails.type === SIDEBAR_INFORMATION.type
                ? blue.secondary
                : 'inherit',
          },
          onClick: () => setSidebarDetails(SIDEBAR_INFORMATION),
        },
      ]}
      defaultOnToggleSideMenu={(open) => {
        setSidebarDetails(open ? SIDEBAR_DEFAULT : SIDEBAR_INFORMATION)
      }}
    />
  )
}
