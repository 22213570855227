import React from 'react'
import {Link} from 'react-router-dom'
import {blue} from 'finsys-webcomponent'

const CustomTabLinkRenderer = ({
  to,
  endToIdentification,
  linkIdentification,
  contentField,
  ...props
}) => {
  const link = `${to}/${
    props.data[endToIdentification]
      ? props.data[endToIdentification].replaceAll(' ', '') + '/'
      : ''
  }${props.data[linkIdentification]}`
  return (
    <>
      <Link
        to={link}
        style={{color: blue.secondary, textDecoration: 'none', cursor: 'pointer'}}
      >
        {props.data[contentField]}
      </Link>
    </>
  )
}

export default CustomTabLinkRenderer
