import {Grid, Stack, Typography} from '@mui/material'
import {fetchGCROtherMapper, submitGCROtherMapper} from 'api/GCR/query/GCRQueries'
import {Accordion, Button, DetailedLayout, TextField, blue} from 'finsys-webcomponent'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {validateForm} from '../Entity/Create'
import useDataFetching from 'hoc/UseDataFetching'
import {getUserRole} from 'localstorage/UserRole'
import {HQ_ROLE_ID, dateTimeFormat} from '_helper/_constants'
import moment from 'moment'
import {queryClient} from 'hoc/ReactQuery'
import {SIDEBAR_DEFAULT, SIDEBAR_INFORMATION} from '..'
import {Info} from '@mui/icons-material'

export const EditGCROther = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [formDetails, setFormDetails] = useState({
    oth_mapping_type: '',
    code: '',
    value: '',
    last_update_date: '',
    last_updated_by: '',
    error_oth_mapping_type: null,
    error_code: null,
    error_value: null,
  })
  const [sidebarDetails, setSidebarDetails] = useState(SIDEBAR_INFORMATION)

  const {data, loading} = useDataFetching(`gcr-other-mapper-${id}`, () =>
    fetchGCROtherMapper(id)
  )

  useEffect(() => {
    if (!data) {
      return
    }
    setFormDetails((prevState) => {
      return {
        ...prevState,
        oth_mapping_type: data.oth_mapping_type,
        code: data.code,
        value: data.value,
        last_update_date: data.last_update_date,
        last_updated_by: data.last_updated_by,
      }
    })
  }, [data])

  const editGCROther = useMutation(() => submitGCROtherMapper(formDetails, id), {
    onSuccess: (result) => {
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          type: 'success',
          message: 'Other Mapper successfully updated',
        }
      })
    },
    onError: (err) => {
      console.log(err)
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
        }
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(`gcr-other-mapper-${id}`)
    },
  })

  const handleValidateForm = () => {
    const requiredFields = ['oth_mapping_type', 'code', 'value']
    validateForm(formDetails, setFormDetails, requiredFields, editGCROther)
  }

  const isAccessRestricted = () => {
    return getUserRole().role !== HQ_ROLE_ID
  }

  return (
    <DetailedLayout
      mainContent={
        <>
          <Accordion title="Details">
            <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Code'}
                  value={formDetails.code}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      code: value.target.value,
                      error_code: null,
                    })
                  }
                  required
                  error={!!formDetails.error_code}
                  helperText={formDetails.error_code}
                  disabled={isAccessRestricted()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Value'}
                  value={formDetails.value}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      value: value.target.value,
                      error_value: null,
                    })
                  }
                  required
                  error={!!formDetails.error_value}
                  helperText={formDetails.error_value}
                  disabled={isAccessRestricted()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={'Other Mapping Type'}
                  value={formDetails.oth_mapping_type}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      oth_mapping_type: value.target.value,
                      error_oth_mapping_type: null,
                    })
                  }
                  required
                  error={!!formDetails.error_oth_mapping_type}
                  helperText={formDetails.error_oth_mapping_type}
                  disabled={isAccessRestricted()}
                />
              </Grid>
            </Grid>
          </Accordion>
          {!isAccessRestricted() && (
            <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
              <Button label="Save" onClick={() => handleValidateForm()} />
            </Stack>
          )}
        </>
      }
      sideDrawerContent={
        <>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
                General
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Updated By"
                value={formDetails.last_updated_by}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Updated On"
                value={moment
                  .utc(formDetails.last_update_date)
                  .local()
                  .format(dateTimeFormat)}
                disabled
              />
            </Grid>
          </Grid>
        </>
      }
      collapseSideDrawer={[
        {
          icon: Info,
          label: 'Information',
          iconStyle: {
            color:
              sidebarDetails.type === SIDEBAR_INFORMATION.type
                ? blue.secondary
                : 'inherit',
          },
          onClick: () => setSidebarDetails(SIDEBAR_INFORMATION),
        },
      ]}
      defaultOnToggleSideMenu={(open) => {
        setSidebarDetails(open ? SIDEBAR_DEFAULT : SIDEBAR_INFORMATION)
      }}
    />
  )
}
