import React from 'react'
import {Grid, Stack} from '@mui/material'
import {IconButton, Button, Autocomplete, Label, FormTable} from 'finsys-webcomponent'
import DeleteIcon from '@mui/icons-material/Delete'

import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'
import EmployeeListForBankAccount from 'components/InternalRequest/Forms/_Common/EmployeeListForBankAccount'
import {ReasonAutocomplete} from 'components/InternalRequest/Forms/BankAccountMaintanence/constant'

/**
 * To validate creation field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @param setFormErrorDetail
 * @returns {boolean}
 */
export const validateDeletionField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo,
  setFormErrorDetail
) => {
  const requiredFields = {
    employeeDetails: [
      {field: 'reason', label: 'Reason'},
      {field: 'fullname', label: 'Full Name'},
      {field: 'approve_type', label: 'Existing Role(To Remove)'},
    ],
    bankAccounts: [
      {field: 'bank_id', label: 'Bank Code'},
      // {field: 'obm_account_id', label: 'Account Number'},
      {field: 'account_number', label: 'Account Number'},
      {field: 'acc_managed_by', label: 'Account Managed By'},
    ],
  }

  let isFormValid = true
  let isEmpValid = true
  let isBankValid = true
  //lets validate form details

  if (formDetail.emp_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'emp_details',
      'Please add at least 1 Employee Detail'
    )
    isFormValid = false
  } else {
    for (const empIndex in formDetail.emp_details) {
      for (const [key, value] of Object.entries(requiredFields.employeeDetails)) {
        if (
          !formDetail.emp_details[empIndex][value.field] ||
          !formDetail.emp_details[empIndex].approve_type?.value ||
          formDetail.emp_details[empIndex][value.field].length === 0
        ) {
          if (isEmpValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'emp_details',
              'Please fill up all the required fields'
            )
            isEmpValid = false
          }
          isFormValid = false
          setFormErrorDetail('emp_details', index, empIndex, value.field)
        }
      }
    }
  }

  if (formDetail.bank_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'bank_details',
      'Please add at least 1 Bank Acccount Detail'
    )
    isFormValid = false
  } else {
    for (const bankIndex in formDetail.bank_details) {
      for (const [key, value] of Object.entries(requiredFields.bankAccounts)) {
        if (
          !formDetail.bank_details[bankIndex][value.field] ||
          formDetail.bank_details[bankIndex][value.field].length === 0
        ) {
          if (isBankValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'bank_details',
              'Please fill up all the required fields'
            )
            isBankValid = false
          }
          isFormValid = false
          setFormErrorDetail('bank_details', index, bankIndex, value.field)
        }
      }
    }
  }

  return isFormValid
}

/**
 * Deletion form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param setArrayValue
 * @param disabled
 * @param reason
 * @param OBMbanks
 * @param bankAccount
 * @param accountMangedBy
 * @returns {JSX.Element}
 * @constructor
 */
export const Deletion = ({
  detail,
  formIndex,
  setFormArrayValue,
  setArrayValue,
  disabled,
  reason,
  OBMbanks,
  bankAccount,
  accountMangedBy,
  formDetails,
}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label label={`Bank Account`} required={true} error={detail.error_bank_details} />
        <FormTable
          tableKey={'deletion_bank_account'}
          columns={[
            {
              name: 'Bank',
              field: 'bank_id',
              width: '30%',
              component: ({value, dataIndex, error}) => (
                <Autocomplete
                  options={OBMbanks ?? []}
                  getOptionLabel={(option) => option.text || ''}
                  handleOnChange={(value) => {
                    ;[
                      'obm_account_id',
                      'account_number',
                      'acc_managed_by',
                      'currency_code',
                    ].forEach((fieldName) => {
                      setArrayValue(formIndex, 'bank_details', dataIndex, fieldName, null)
                    })

                    setArrayValue(formIndex, 'bank_details', dataIndex, 'bank_id', value)
                  }}
                  value={value}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Bank Account Number',
              // field: 'obm_account_id',
              field: 'account_number',
              width: '20%',
              component: ({value, dataIndex, error, data}) => {
                return (
                  <Autocomplete
                    options={
                      bankAccount[detail.bank_details[dataIndex].bank_id?.value]
                        ? [
                            {
                              acc_managed_by: null,
                              account_number: 'All Account',
                              currency_code: 'ALL',
                              account_type: null,
                              entity_id: 'ALL',
                              entity_name: null,
                            },
                            ...(bankAccount[
                              detail.bank_details[dataIndex].bank_id?.value
                            ] ?? []),
                          ]
                        : detail.bank_details[dataIndex].bank_id?.text ===
                          'All Banks (ALL)'
                        ? [
                            {
                              acc_managed_by: null,
                              account_number: 'All Account',
                              currency_code: 'ALL',
                              account_type: null,
                              entity_id: null,
                              entity_name: null,
                            },
                          ]
                        : []
                    }
                    getOptionLabel={(option) => {
                      return option.account_number === 'All Account'
                        ? option.account_number
                        : option.obm_account_id &&
                          option.account_number &&
                          option.entity_code != null
                        ? `${option.account_number} - ${option.entity_code}`
                        : option.obm_account_id &&
                          option.account_number &&
                          option.entity_code != null
                        ? option.account_number
                        : ''
                    }}
                    handleOnChange={(value) => {
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'obm_account_id',
                        value?.obm_account_id
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'account_number',
                        value?.account_number
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'entity_code',
                        value?.entity_code
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'entity_id',
                        value?.entity_id
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'currency_code',
                        value?.currency_code
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'account_type',
                        value?.account_type
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'acc_managed_by',
                        value?.acc_managed_by
                      )
                    }}
                    value={data}
                    textFieldProps={{
                      error: !!error,
                      sx: {
                        '& label': {display: 'none'},
                        '& .MuiInput-root': {mt: 0},
                      },
                    }}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              name: 'Account Type',
              width: '5%',
              field: 'account_type',
            },
            {
              name: 'Currency',
              width: '5%',
              field: 'currency_code',
            },
            {
              name: 'Managed By',
              field: 'acc_managed_by',
              width: '12%',
              component: ({value, dataIndex, data, error}) => {
                const selectManagedBy = data?.account_number === 'All Account'

                if (selectManagedBy) {
                  return (
                    <Autocomplete
                      options={accountMangedBy ?? []}
                      getOptionLabel={(option) => option.text ?? option}
                      handleOnChange={(value) => {
                        setArrayValue(
                          formIndex,
                          'bank_details',
                          dataIndex,
                          'acc_managed_by',
                          value.value
                        )
                      }}
                      value={value ?? ''}
                      textFieldProps={{
                        error: !!error,
                        sx: {
                          '& label': {display: 'none'},
                          '& .MuiInput-root': {mt: 0},
                        },
                      }}
                      disabled={!selectManagedBy || disabled}
                    />
                  )
                } else {
                  return value?.text === null ? '' : value?.text ?? value
                }
              },
            },
            ...(!disabled
              ? [
                  {
                    name: '',
                    field: '',
                    width: '1%',
                    component: ({dataIndex}) => {
                      return (
                        <IconButton
                          title={'Delete'}
                          handleOnClick={() => {
                            let newBankDetails = detail.bank_details
                            newBankDetails.splice(dataIndex, 1)
                            setFormArrayValue(
                              formIndex,
                              'form_details',
                              'bank_details',
                              newBankDetails
                            )
                          }}
                        >
                          <DeleteIcon style={{color: '#CE6B72'}} />
                        </IconButton>
                      )
                    },
                  },
                ]
              : []),
          ]}
          data={detail.bank_details}
        />
        {!disabled && (
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button
              label={'Add Bank Details'}
              type={'secondary'}
              size={'small'}
              onClick={() => {
                const newBankDetails = [
                  ...detail.bank_details,
                  {
                    role: '',
                    group: '',
                    bank_id: '',
                    entity_id: null,
                    currency_code: '',
                    account_number: '',
                    acc_managed_by: '',
                    account_type: '',
                  },
                ]
                setFormArrayValue(
                  formIndex,
                  'form_details',
                  'bank_details',
                  newBankDetails
                )
              }}
            />
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label
          label={`Employee Details`}
          required={true}
          error={detail.error_emp_details}
        />

        <FormTable
          tableKey={'deletion_employee'}
          columns={[
            {
              name: 'Reason',
              field: 'reason',
              width: '25%',
              component: ({value, dataIndex, error}) => {
                return (
                  <ReasonAutocomplete
                    error={error}
                    options={reason ?? []}
                    table={true}
                    value={value}
                    disabled={disabled}
                    onChange={(value) =>
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'reason',
                        value?.text
                      )
                    }
                  />
                )
              },
            },
            {
              name: 'Name',
              field: 'fullname',
              width: '25%',
              component: ({value, dataIndex, error}) => {
                let selectedBankAccount = detail.bank_details
                  .filter((value) => value.account_number)
                  .map((value) => value.account_number)

                let selectedEntity = detail.bank_details
                  .filter((value) => value.entity_id && value.entity_id !== 'ALL')
                  .map((value) => value.entity_id)

                let selectedBankId = detail.bank_details
                  .filter((value) => value.bank_id)
                  .map((value) => value.bank_id)

                return (
                  <EmployeeListForBankAccount
                    error={error}
                    entityId={
                      selectedEntity?.length === 0
                        ? formDetails?.entity_id.map(({entity_id}) => entity_id)
                        : selectedEntity
                    }
                    bankId={selectedBankId}
                    bankAccount={selectedBankAccount}
                    disabled={disabled}
                    value={value}
                    table={true}
                    setApproveTypeOptions={(value) => {
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'approve_type_options',
                        value?.approve_type
                      )
                    }}
                    setEmployeeName={(value) => {
                      setArrayValue(formIndex, 'emp_details', dataIndex, 'fullname', {
                        full_name: value?.full_name,
                        email: value?.email,
                      })
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'approve_type_options',
                        value?.approve_type
                      )
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'email',
                        value?.email
                      )
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'company_designation',
                        value?.designation
                      )
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'entity_id',
                        value?.entity_id
                      )
                      setArrayValue(formIndex, 'emp_details', dataIndex, 'isNew', false)
                    }}
                  />
                )
              },
            },
            {
              name: 'Company Designation',
              field: 'company_designation',
              width: '20%',
              component: ({value}) => value,
            },
            {
              name: 'Existing Role(To Remove)',
              field: 'approve_type',
              component: ({value, dataIndex, error}) => {
                return (
                  <Autocomplete
                    options={[
                      {
                        text: 'All Roles',
                        value: 'all_roles',
                      },
                      ...(detail?.emp_details[dataIndex]?.approve_type_options ?? []),
                    ]}
                    getOptionLabel={(option) => {
                      return option.text ?? option
                    }}
                    handleOnChange={(value) => {
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'approve_type',
                        value
                      )
                    }}
                    value={value}
                    textFieldProps={{
                      error: !!error,
                      sx: {
                        '& label': {display: 'none'},
                        '& .MuiInput-root': {mt: 0},
                      },
                    }}
                    disabled={disabled}
                  />
                )
              },
            },
            ...(!disabled
              ? [
                  {
                    name: '',
                    field: '',
                    width: '1%',
                    component: ({dataIndex}) => {
                      return (
                        <IconButton
                          title="Delete"
                          handleOnClick={() => {
                            let newEmpDetails = detail.emp_details
                            newEmpDetails.splice(dataIndex, 1)
                            setFormArrayValue(
                              formIndex,
                              'form_details',
                              'emp_details',
                              newEmpDetails
                            )
                          }}
                        >
                          <DeleteIcon style={{color: '#CE6B72'}} />
                        </IconButton>
                      )
                    },
                  },
                ]
              : []),
          ]}
          data={detail.emp_details}
        />

        {!disabled && (
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button
              label={'Add Employee Details'}
              type={'secondary'}
              size={'small'}
              onClick={() => {
                const newEmpDetails = [
                  ...detail.emp_details,
                  {
                    company_designation: '',
                    reason: '',
                    fullname: '',
                    email: '',
                    is_new: '',
                    entity_id: [],
                  },
                ]
                setFormArrayValue(formIndex, 'form_details', 'emp_details', newEmpDetails)
              }}
            />
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
