import React from 'react'
import {Grid, Stack, Typography} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  TextField,
  Button,
  IconButton,
  Autocomplete,
  Label,
  FormTable,
} from 'finsys-webcomponent'

import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'

/**
 * To validate System Admin Update field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @param setFormErrorDetail
 * @returns {boolean}
 */
export const validateSystemAdminUpdateField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo,
  setFormErrorDetail
) => {
  const requiredFields = {
    mandatory: [
      {field: 'reason_for_change', label: 'Reason For Change'},
      {field: 'new_system_admin_setup', label: 'New System Admin'},
    ],
    bankAccounts: [
      {field: 'bank_id', label: 'Bank Code'},
      // {field: 'obm_account_id', label: 'Account Number'},
      {field: 'account_number', label: 'Account Number'},
      {field: 'acc_managed_by', label: 'Account Managed By'},
    ],
  }

  let isFormValid = true

  for (const [key, value] of Object.entries(requiredFields.mandatory)) {
    if (!formDetail[value.field] || formDetail[value.field].length === 0) {
      setFormArrayErrorValue(
        setBankAccountMaintenanceInfo,
        index,
        'form_details',
        value.field
      )
      isFormValid = false
    }
  }

  let isBankValid = true

  if (formDetail.bank_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'bank_details',
      'Please add at least 1 Bank Acccount Detail'
    )
    isFormValid = false
  } else {
    for (const bankIndex in formDetail.bank_details) {
      for (const [key, value] of Object.entries(requiredFields.bankAccounts)) {
        if (
          !formDetail.bank_details[bankIndex][value.field] ||
          formDetail.bank_details[bankIndex][value.field].length === 0
        ) {
          if (isBankValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'bank_details',
              'Please fill up all the required fields'
            )
            isBankValid = false
          }
          isFormValid = false
          setFormErrorDetail('bank_details', index, bankIndex, value.field)
        }
      }
    }
  }

  return isFormValid
}

/**
 * System admin update form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param setArrayValue
 * @param setArrayValue
 * @param disabled
 * @param OBMbanks
 * @param accountMangedBy
 * @param bankAccount
 * @param accountMangedBy
 * @returns {JSX.Element}
 * @constructor
 */
export const SystemAdminUpdate = ({
  detail,
  formIndex,
  setFormArrayValue,
  setArrayValue,
  disabled,
  OBMbanks,
  bankAccount,
  accountMangedBy,
}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label label={`Bank Account`} required={true} error={detail.error_bank_details} />
        <FormTable
          tableKey={'sys_admin_update_bank_acc'}
          columns={[
            {
              name: 'Bank',
              field: 'bank_id',
              width: '30%',
              component: ({value, dataIndex, error}) => (
                <Autocomplete
                  options={OBMbanks}
                  getOptionLabel={(option) => option.text || ''}
                  handleOnChange={(value) => {
                    ;[
                      'obm_account_id',
                      'account_number',
                      'acc_managed_by',
                      'currency_code',
                    ].forEach((fieldName) => {
                      setArrayValue(formIndex, 'bank_details', dataIndex, fieldName, null)
                    })

                    setArrayValue(formIndex, 'bank_details', dataIndex, 'bank_id', value)
                  }}
                  value={value}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Bank Account Number',
              // field: 'obm_account_id',
              field: 'account_number',
              width: '20%',
              component: ({value, dataIndex, error, data}) => (
                <Autocomplete
                  options={
                    bankAccount[detail.bank_details[dataIndex].bank_id?.value]
                      ? [
                          {
                            acc_managed_by: null,
                            account_number: 'All Account',
                            currency_code: 'ALL',
                            account_type: null,
                            entity_id: 'ALL',
                            entity_name: null,
                          },
                          ...(bankAccount[
                            detail.bank_details[dataIndex].bank_id?.value
                          ] ?? []),
                        ]
                      : detail.bank_details[dataIndex].bank_id?.text === 'All Banks (ALL)'
                      ? [
                          {
                            acc_managed_by: null,
                            account_number: 'All Account',
                            currency_code: 'ALL',
                            account_type: null,
                            entity_id: null,
                            entity_name: null,
                          },
                        ]
                      : []
                  }
                  getOptionLabel={(option) => {
                    return option.account_number === 'All Account'
                      ? option.account_number
                      : option.obm_account_id &&
                        option.account_number &&
                        option.entity_code != null
                      ? `${option.account_number} - ${option.entity_code}`
                      : option.obm_account_id &&
                        option.account_number &&
                        option.entity_code != null
                      ? option.account_number
                      : ''
                  }}
                  handleOnChange={(value) => {
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'obm_account_id',
                      value?.obm_account_id
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'account_number',
                      value?.account_number
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'entity_code',
                      value?.entity_code
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'entity_id',
                      value?.entity_id
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'currency_code',
                      value?.currency_code
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'account_type',
                      value?.account_type
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'acc_managed_by',
                      value?.acc_managed_by
                    )
                  }}
                  value={data}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Account Type',
              width: '5%',
              field: 'account_type',
            },
            {
              name: 'Currency',
              width: '5%',
              field: 'currency_code',
            },
            {
              name: 'Managed By',
              field: 'acc_managed_by',
              width: '12%',
              // dependentValue: 'obm_account_id',
              dependentValue: 'account_number',
              component: ({value, dataIndex, data, error}) => {
                const selectManagedBy = data?.account_number === 'All Account'

                if (selectManagedBy) {
                  return (
                    <Autocomplete
                      options={accountMangedBy}
                      getOptionLabel={(option) => option.text ?? option}
                      handleOnChange={(value) => {
                        setArrayValue(
                          formIndex,
                          'bank_details',
                          dataIndex,
                          'acc_managed_by',
                          value.value
                        )
                      }}
                      value={value ?? ''}
                      textFieldProps={{
                        error: !!error,
                        sx: {
                          '& label': {display: 'none'},
                          '& .MuiInput-root': {mt: 0},
                        },
                      }}
                      disabled={!selectManagedBy || disabled}
                    />
                  )
                } else {
                  return value ?? ''
                }
              },
            },
            ...(!disabled
              ? [
                  {
                    name: '',
                    field: '',
                    width: '1%',
                    component: ({dataIndex}) => {
                      return (
                        <IconButton
                          title="Delete"
                          handleOnClick={() => {
                            let newBankDetails = detail.bank_details
                            newBankDetails.splice(dataIndex, 1)
                            setFormArrayValue(
                              formIndex,
                              'form_details',
                              'bank_details',
                              newBankDetails
                            )
                          }}
                        >
                          <DeleteIcon style={{color: '#CE6B72'}} />
                        </IconButton>
                      )
                    },
                  },
                ]
              : []),
          ]}
          data={detail.bank_details}
        />
        {!disabled && (
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button
              label={'Add Bank Details'}
              type={'secondary'}
              size={'small'}
              onClick={() => {
                const newBankDetails = [
                  ...detail.bank_details,
                  {
                    role: '',
                    group: '',
                    bank_id: '',
                    entity_id: null,
                    currency_code: '',
                    account_number: '',
                    acc_managed_by: '',
                    account_type: '',
                  },
                ]
                setFormArrayValue(
                  formIndex,
                  'form_details',
                  'bank_details',
                  newBankDetails
                )
              }}
            />
          </Stack>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
          Details
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={'Reason For Change'}
          value={detail.reason_for_change ?? ''}
          onChange={(event) => {
            setFormArrayValue(
              formIndex,
              'form_details',
              'reason_for_change',
              event.target.value
            )
          }}
          disabled={disabled}
          required
          error={!!detail.error_reason_for_change}
          helperText={detail.error_reason_for_change}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={'New System Admin Setup'}
          value={detail.new_system_admin_setup ?? ''}
          onChange={(event) => {
            setFormArrayValue(
              formIndex,
              'form_details',
              'new_system_admin_setup',
              event.target.value
            )
          }}
          minRows={2}
          maxRows={3}
          multiline
          disabled={disabled}
          required
          error={!!detail.error_new_system_admin_setup}
          helperText={
            detail.error_new_system_admin_setup ? (
              detail.error_new_system_admin_setup
            ) : (
              <>
                Example: <br />
                System Admin Maker: XXX <br />
                System Admin Approver: YYY/ZZZ
              </>
            )
          }
        />
      </Grid>
    </Grid>
  )
}
