import {useState} from 'react'
import moment from 'moment'
import * as Excel from 'exceljs'
import {saveAs} from 'file-saver'

export const useExcelUpload = () => {
  const [fileUploadDialog, setFileUploadDialog] = useState({
    open: false,
    attachment: null,
  })
  const [uploadResultDialog, setUploadResultDialog] = useState({
    open: false,
    content: {},
    upload_error: false,
  })

  const writeErrorExcel = (data_log) => {
    const workbook = new Excel.Workbook()

    const formatData = (data) => {
      return data.error.map((error) => ({
        row_number: error.row_number,
        error: error.error.map((message) => `•  ${message}`).join('\r\n'),
      }))
    }
    const excelColumns = [
      {
        header: 'Row Number',
        key: 'row_number',
        width: 20,
        style: {
          alignment: {
            horizontal: 'center',
            vertical: 'middle',
          },
        },
      },
      {
        header: 'Error Message',
        key: 'error',
        width: 100,
        style: {alignment: {wrapText: true}},
      },
    ]
    const createWorkSheet = (data, name) => {
      let excel_worksheet = workbook.addWorksheet(name)
      excel_worksheet.columns = excelColumns
      let formattedData = formatData(data)
      excel_worksheet.addRows(formattedData)
    }

    if (data_log.entity) {
      createWorkSheet(data_log.entity, 'Entity')
    }
    if (data_log.other_mapper) {
      createWorkSheet(data_log.other_mapper, 'Other Mapper')
    }
    if (data_log.bank) {
      createWorkSheet(data_log.bank, 'Bank')
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const fileExtension = '.xlsx'
    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {type: fileType})

        const formattedDate = moment().format('YYYYMMDD_HHmm')
        const fileName = `gcr_upload_error_log_${formattedDate}${fileExtension}`

        saveAs(blob, fileName)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  return {
    writeErrorExcel,
    fileUploadDialog,
    setFileUploadDialog,
    uploadResultDialog,
    setUploadResultDialog,
  }
}
