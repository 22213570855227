import NewTabLinkRenderer from 'components/AgGrid/NewTabLinkRenderer'
import CustomTabLinkRenderer from 'components/AgGrid/CustomTabLinkRenderer'

export const columnDefs = [
  {
    field: 'code',
    headerName: 'Code',
    cellRenderer: 'newTabLinkRenderer',
    cellRendererParams: {
      to: '/Gcr/MetadataGcr/Entity',
      linkIdentification: 'code',
      contentField: 'code',
    },
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'region_mapping',
    headerName: 'MTR Region',
  },
  {
    field: 'country_for_gct_grouping',
    headerName: 'GCR Region',
  },
  {
    field: 'hq_tag_for_gcr',
    headerName: 'GCR HQ Tag',
  },
]

export const frameworkComponents = {
  newTabLinkRenderer: NewTabLinkRenderer,
  customTabLinkRenderer: CustomTabLinkRenderer,
}
