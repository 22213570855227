import TSApi from 'api/TS/TSApi'

function downloadFile(data) {
  const fileName = data.headers['content-disposition']
    .split('filename=')[1]
    .replaceAll('"', '')

  const url = window.URL.createObjectURL(new Blob([data.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()

  return true
}

export async function retrieveGroupPIC(group_name) {
  let query = '/gcr/gcr_group_pic/'
  if (group_name) {
    query += `?group_name=${group_name}`
  }
  const {data} = await TSApi.get(query)

  return data.data
}

export async function retrieveHfmLastSync() {
  const {data} = await TSApi.get('/gcr/get_hfm_last_sync/')

  return data
}

export async function retrieveKyribaLastSync() {
  const {data} = await TSApi.get('/gcr/get_kyriba_last_sync/')

  if (data?.length > 0) {
    return data[0]
  } else {
    return data
  }
}

export async function retrieveEntityRegions() {
  const {data} = await TSApi.get('/gcr/gcr_regions/')

  const filteredRegions = data?.data.filter((region) => {
    return region?.region_mapping?.trim() !== ''
  })

  return filteredRegions
}

export async function retrieveDataSyncHistory(limit, page, year, month) {
  let query = `/gcr/gcr_data_sync_history_paginated/?limit=${limit}&page=${page}`
  if (year) {
    query += `&year=${year}`
  }
  if (month) {
    query += `&month=${month}`
  }
  const {data} = await TSApi.get(query)
  return data
}

export async function syncKyribaData(year, month) {
  const {data} = await TSApi.put(`/gcr/load_kyriba/?active_period=${year}${month}`)

  return data
}

export async function downloadMTR3Document(year, month, regions) {
  month = month.length < 2 ? '0' + month : month
  let query = `/gcr/export/mtrwd03/?report_yearmonth=${year}${month}`
  regions.forEach((region) => {
    query += `&region=${region.region_mapping}`
  })

  const data = await TSApi.get(query, {
    responseType: 'blob',
  })

  return downloadFile(data)
}

export async function downloadGCR6Document(year, month) {
  month = month.length < 2 ? '0' + month : month
  let query = `/gcr/export/gcrwd6/?report_yearmonth=${year}${month}`

  const data = await TSApi.get(query, {
    responseType: 'blob',
  })

  return downloadFile(data)
}

export async function downloadMTR10Document(year, month, regions) {
  month = month.length < 2 ? '0' + month : month
  let query = `/gcr/export/mtrwd10/?report_yearmonth=${year}${month}`
  regions.forEach((region) => {
    query += `&region=${region.region_mapping}`
  })

  const data = await TSApi.get(query, {
    responseType: 'blob',
  })

  return downloadFile(data)
}

export async function fetchGCRMetaDataPaginated(tab, page, limit, search) {
  let query = `/gcr/${tab}_paginated/?limit=${limit}&page=${page}`
  if (search) {
    query += `&search=${search}`
  }
  const {data} = await TSApi.get(query)

  return data
}

export async function fetchGCREntity(entity_id) {
  const {data} = await TSApi.get(`/gcr/submit_gcr_entity/${entity_id}/`)

  return data
}

export async function submitGCREntity(entity, entity_id) {
  let query = '/gcr/submit_gcr_entity/'

  if (entity_id) {
    query += `${entity_id}/`
    const {data} = await TSApi.put(query, entity)
    return data
  } else {
    const {data} = await TSApi.post(query, entity)
    return data
  }
}

export async function fetchGCROtherMapper(oth_mapping_id) {
  const {data} = await TSApi.get(`/gcr/submit_gcr_other_mapper/${oth_mapping_id}/`)

  return data
}

export async function submitGCROtherMapper(other_mapper, oth_mapping_id) {
  let query = '/gcr/submit_gcr_other_mapper/'

  if (oth_mapping_id) {
    query += `${oth_mapping_id}/`
    const {data} = await TSApi.put(query, other_mapper)
    return data
  } else {
    const {data} = await TSApi.post(query, other_mapper)
    return data
  }
}

export async function fetchGCRBankRating(bank_id) {
  const {data} = await TSApi.get(`/gcr/submit_gcr_rating/${bank_id}/`)

  return data
}

export async function fetchGCRBankCodeBankName() {
  const {data} = await TSApi.get(`/gcr/fetch_bankcode_bankname/`)

  return data
}

export async function submitGCRBankRating(bank_rating, bank_id) {
  let query = '/gcr/submit_gcr_rating/'
  let requestBody = {
    bank_code: bank_rating?.bank_code,
    region_code: bank_rating?.region_code,
    one_yr_cds: ['N.A.', ''].includes(bank_rating?.one_yr_cds?.trim())
      ? bank_rating?.one_yr_cds.trim()
      : bank_rating?.one_yr_cds,
    avg_sum_of_rating: bank_rating?.avg_sum_of_rating,
    rating_type: bank_rating?.rating_type,
    avg_sum_of_rating_snp_equivalent: bank_rating?.avg_sum_of_rating_snp_equivalent,
  }

  if (bank_id) {
    query += `${bank_id}/`
    const {data} = await TSApi.put(query, requestBody)
    return data
  } else {
    const {data} = await TSApi.post(query, requestBody)
    return data
  }
}

export async function uploadGCRMetaDataFile(file) {
  let formData = new FormData()
  formData.append('file', file)
  const {data} = await TSApi.post('/gcr/upload_gcr_file/', formData)

  return data
}

export async function exportGCRMetaDataFile(tab) {
  let query = `/gcr/export_metadata/?tab=${tab}`

  const data = await TSApi.get(query, {
    responseType: 'blob',
  })

  return downloadFile(data)
}
