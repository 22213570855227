import {BankRating} from 'components/GCR/Metadata/BankRating'
import {Entity} from 'components/GCR/Metadata/Entity'
import {OtherMapper} from 'components/GCR/Metadata/OtherMapper'
import {Tabs} from 'finsys-webcomponent'
import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

export const SIDEBAR_INFORMATION = {
  type: 'INFORMATION',
  title: 'Basic Information',
}

export const SIDEBAR_DEFAULT = {
  type: null,
  title: '',
}

export const MetadataGCR = () => {
  const {metadata} = useParams()
  const navigate = useNavigate()

  let tabIndex = 0
  switch (metadata) {
    case 'Entity':
      tabIndex = 0
      break
    case 'Other':
      tabIndex = 1
      break
    case 'BankRating':
      tabIndex = 2
      break
    default:
      tabIndex = 0
  }

  useEffect(() => {
    if (!metadata) {
      return
    }
    navigate(`/Gcr/MetadataGcr?${metadata}`)
  }, [metadata])

  return (
    <Tabs
      tabList={[
        {label: 'Entity', panel: <Entity />},
        {label: 'Other Mapper', panel: <OtherMapper />},
        {label: 'Bank Rating', panel: <BankRating />},
      ]}
      tabIndex={tabIndex}
    />
  )
}
