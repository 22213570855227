import {TabContent} from '../TabContent'
import {columnDefs} from './ag-grid-constants'

export const Entity = () => {
  return (
    <TabContent
      columnDefs={columnDefs}
      tab={'Entity'}
      label={'Entity'}
      api={'gcr_dim_entity'}
      searchInput={'code, description'}
    />
  )
}
