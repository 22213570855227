import useDataFetching from 'hoc/UseDataFetching'
import type {DefaultSelectType} from 'api/TS/query/_CommonQueries'
import {
  fetchFormMetadata,
  fetchOnlineBankingMatrixAccount,
  fetchPreviousRejectList,
  fetchTicketHistory,
  fetchTicketProgressIndicator,
  retrieveOBMEmployee,
} from 'api/TS/query/InternalRequestQueries'
import type {FetchOnlineBankingMatrixAccountType} from 'api/TS/query/InternalRequestQueries'
import {fetchCategory} from 'api/TS/query/POAEditQueries'
import {
  CountryType,
  EntitiesType,
  fetchAccountManagedBy,
  fetchBankName,
  fetchCountry,
  fetchCurrencies,
  fetchEmployeeGroup,
  fetchEmployeeTitle,
  fetchEntities,
  fetchMatrixCondition,
  fetchMatrixThreshold,
  fetchOBMBank,
  fetchSegments,
  fetchUserOBMAccess,
} from 'api/TS/query/_CommonQueries'

/**
 *  To retrieve bank
 * @return {{isError: false | true, data: DefaultSelectType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
export function useFetchBankNames<TData = DefaultSelectType>() {
  return useDataFetching(`bankNames`, () => fetchBankName())
}

/**
 *  To retrieve OBM bank
 * @return {{isError: false | true, data: DefaultSelectType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
export function useFetchOBMBankNames<TData = DefaultSelectType>(
  entity: string,
  subCategory: string
) {
  return useDataFetching(
    `OBMBankNames-${entity}-${subCategory}`,
    () => fetchOBMBank(entity, subCategory),
    {
      enabled: !!entity && !!subCategory,
    }
  )
}

/**
 * To retrieve account managed by
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchAccountManagedBy<TData = DefaultSelectType>() {
  return useDataFetching(`accountManagedBy`, () => fetchAccountManagedBy())
}

/**
 * To retrieve currencies
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchCurrencies<TData = DefaultSelectType>() {
  return useDataFetching('currencies', () => fetchCurrencies())
}

/**
 * To retrieve matrix conditions
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchMatrixConditions<TData = DefaultSelectType>() {
  return useDataFetching('matrixConditions', () => fetchMatrixCondition())
}

/**
 * To retrieve matrix threshold
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchMatrixThresholds<TData = DefaultSelectType>() {
  return useDataFetching('matrixThreshold', () => fetchMatrixThreshold())
}

/**
 * To retrieve employee title
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchEmployeeTitle<TData = DefaultSelectType>() {
  return useDataFetching('employeeTitle', () => fetchEmployeeTitle())
}

/**
 * To retrieve employee group
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchEmployeeGroup<TData = DefaultSelectType>() {
  return useDataFetching('employeeGroup', () => fetchEmployeeGroup())
}

/**
 * To retrieve form metadata
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchFormMetadata<TData = DefaultSelectType>(
  form: string,
  type: string
) {
  return useDataFetching(`${form}-${type}-metadata`, () => fetchFormMetadata(form, type))
}

/**
 * To retrieve bank account from online bank matrix based on entity
 * @return {{isError: boolean, data: FetchOnlineBankingMatrixAccountType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchOBMBankAccount(
  entity: string,
  subCategory: string,
  accToXfer?: boolean
): FetchOnlineBankingMatrixAccountType {
  return useDataFetching(
    `OBM-BankAccount-${entity}-${subCategory}-${accToXfer}`,
    () => fetchOnlineBankingMatrixAccount(entity, subCategory, accToXfer),
    {
      enabled: !!entity && !!subCategory,
    }
  )
}

/**
 *  To retrieve poa category list
 * @return {{isError: false | true, data: DefaultSelectType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
export function useFetchPOACategory<TData = DefaultSelectType>() {
  return useDataFetching(`poa-categories`, () => fetchCategory())
}

/**
 *  To retrieve countries
 * @return {{isError: false | true, data: CountryType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
export function useFetchCountry<TData = CountryType>() {
  return useDataFetching(`countries`, () => fetchCountry())
}

/**
 *  To retrieve segments
 * @return {{isError: false | true, data: CountryType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
export function useFetchSegment<TData = DefaultSelectType>() {
  return useDataFetching(`segments`, () => fetchSegments())
}

/**
 *  To retrieve entities
 * @return {{isError: false | true, data: CountryType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
export function useFetchEntities<TData = EntitiesType>(countries = []) {
  return useDataFetching([`entities`, countries], () => fetchEntities(countries))
}

/**
 * To retrieve form previous reject list for rejection
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchPreviousRejectList<
  TData = [{user_email: string, full_name: string, step_name: string, order: number}]
>(ticketId: string, orderNo: string) {
  return useDataFetching(
    `ticket-${ticketId}-${orderNo}-previous-steps`,
    () => fetchPreviousRejectList(ticketId, orderNo),
    {
      enabled: !!orderNo,
    }
  )
}

/**
 * To retrieve form previous step for rejection
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchTicketProgressIndicator<
  TData = [
    {
      user_email: string,
      full_name: string,
      step_name: string,
      order: number,
      pic_email: null | string,
      approve_date_format: null | string,
    }
  ]
>(ticketId: string) {
  return useDataFetching(
    `ticket-${ticketId}-progress-indicator`,
    () => fetchTicketProgressIndicator(ticketId),
    {
      enabled: !!ticketId,
    }
  )
}

/**
 * To retrieve form history
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchTicketHistory<
  TData = [{user_email: string, full_name: string, step_name: string, order: number}]
>(ticketId: string) {
  return useDataFetching(
    `ticket-${ticketId}-history`,
    () => fetchTicketHistory(ticketId),
    {
      enabled: !!ticketId,
    }
  )
}

/**
 * To retrieve the user OBM access
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchUserOBMAccess(emails: string) {
  return useDataFetching(`user-obm-details-${emails}`, () => fetchUserOBMAccess(emails), {
    enabled: emails?.length >= 0,
  })
}

/**
 * To retrieve account managed by
 * @return {{isError: boolean, data: DefaultSelectType, isPreviousData: boolean, loading: boolean, error: unknown}}
 */
export function useFetchOBMEmployee(bankId, bankAccount, entityId) {
  return useDataFetching(`obmemployee-${bankId}-${bankAccount}-${entityId}`, () =>
    retrieveOBMEmployee(bankId, bankAccount, entityId)
  )
}
