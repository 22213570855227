export const columnDefs = [
  {
    headerName: 'Code',
    cellRenderer: 'customTabLinkRenderer',
    cellRendererParams: {
      to: '/Gcr/MetadataGcr/BankRating',
      linkIdentification: 'bank_rating_id',
      contentField: 'concat_bank_region',
    },
  },
  {
    field: 'bank_fullname',
    headerName: 'Bank Name',
    minWidth: 300,
  },
  {
    field: 'region_code',
    headerName: 'Region Code',
  },
  {
    field: 'one_yr_cds',
    headerName: 'One Year CDS',
  },
  {
    field: 'avg_sum_of_rating',
    headerName: 'Average Sum Of Rating',
  },
  {
    field: 'rating_type',
    headerName: 'Rating Type',
  },
  {
    field: 'avg_sum_of_rating_snp_equivalent',
    headerName: 'Average/Summary Of Rating (S&P equivalent)',
  },
]
