import React from 'react'
import {Autocomplete} from 'finsys-webcomponent'

import {
  useFetchEmployeeGroup,
  useFetchFormMetadata,
  useFetchAccountManagedBy,
} from 'components/_Utils/UseFetchFunctions'

/**
 * Managed By component
 * @returns {JSX.Element}
 * @constructor
 */
export const RoleAutocomplete = ({value, error, onChange, disabled}) => {
  const {loading: isRoleLoading, data: roles} = useFetchFormMetadata(
    'acc_maintain',
    'role'
  )
  return (
    <Autocomplete
      loading={isRoleLoading}
      options={roles}
      getOptionLabel={(option) => option.text ?? option}
      handleOnChange={onChange}
      value={value}
      textFieldProps={{
        error: !!error,
        sx: {
          '& label': {display: 'none'},
          '& .MuiInput-root': {mt: 0},
        },
      }}
      disabled={disabled}
    />
  )
}

/**
 * Grouping component
 * @returns {JSX.Element}
 * @constructor
 */
export const ReasonAutocomplete = ({
  value,
  error,
  index,
  onChange,
  disabled,
  table = false,
  options,
}) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.text || option}
      handleOnChange={onChange}
      value={value}
      label={table ? '' : 'Select Reason'}
      textFieldProps={{
        error: !!error,
        sx: {
          '& label': {display: 'none'},
          '& .MuiInput-root': {mt: 0},
        },
      }}
      disabled={disabled}
    />
  )
}
/**
 * Grouping component
 * @returns {JSX.Element}
 * @constructor
 */
export const GroupingAutocomplete = ({value, error, onChange, disabled}) => {
  const {loading: isEmployeeGroupLoading, data: group} = useFetchEmployeeGroup()

  return (
    <Autocomplete
      loading={isEmployeeGroupLoading}
      options={group}
      getOptionLabel={(option) => option.text || option}
      handleOnChange={onChange}
      value={value}
      textFieldProps={{
        error: !!error,
        sx: {
          '& label': {display: 'none'},
          '& .MuiInput-root': {mt: 0},
        },
      }}
      disabled={disabled}
    />
  )
}
/**
 * Grouping component
 * @returns {JSX.Element}
 * @constructor
 */
export const AccManagedByAutocomplete = ({value, error, onChange, disabled}) => {
  const {loading: isAccountMangedByLoading, data: accountManagedBy} =
    useFetchAccountManagedBy('acc_maintain', 'acc_managed_by')
  return (
    <Autocomplete
      loading={isAccountMangedByLoading}
      options={accountManagedBy}
      getOptionLabel={(option) => option.text || option}
      handleOnChange={onChange}
      value={value}
      textFieldProps={{
        error: !!error,
        sx: {
          '& label': {display: 'none'},
          '& .MuiInput-root': {mt: 0},
        },
      }}
      disabled={disabled}
    />
  )
}
/**
 * Grouping component
 * @returns {JSX.Element}
 * @constructor
 */
export const SubCategoryAutocomplete = ({value, error, onChange, disabled}) => {
  const {loading: isSubCategoryByLoading, data: accountType} = useFetchFormMetadata(
    'acc_maintain',
    'account_type'
  )
  return (
    <Autocomplete
      loading={isSubCategoryByLoading}
      options={accountType}
      getOptionLabel={(option) => option.text || option}
      handleOnChange={onChange}
      value={value}
      textFieldProps={{
        error: !!error,
        sx: {
          '& label': {display: 'none'},
          '& .MuiInput-root': {mt: 0},
        },
      }}
      disabled={disabled}
    />
  )
}
