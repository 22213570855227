import React from 'react'
import {RadioButton, TextField, Label} from 'finsys-webcomponent'
import {Box, Divider, Typography, Grid, Chip} from '@mui/material'
import moment from 'moment'

import {
  dateTimeFormat,
  APP_NAME,
  dateTimeFormatTable,
  FORM_BANK_ACCOUNT_OPENING,
  FORM_BANK_ACCOUNT_CLOSING,
  FORM_BANK_ACCOUNT_MAINTENANCE,
  FORM_BANK_GUARANTEE,
  FORM_DOCUMENT_SIGNING_REQUEST,
} from '_helper/_constants'
import {
  TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE,
  TYPE_OF_CHANGE_AUTHORIZED_PERSON_UPDATE,
  TYPE_OF_CHANGE_CREATION,
  TYPE_OF_CHANGE_DELETION,
  TYPE_OF_CHANGE_MANUAL_PAYMENTS_UPDATE,
  TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE,
  TYPE_OF_CHANGE_USER_ROLE_UPDATE,
  TYPE_OF_CHANGE_BANK_ACCOUNT_NUMBER_UPDATE,
  TYPE_OF_CHANGE_ACC_MANAGED_BY_SUB_CAT_UPDATE,
} from 'components/InternalRequest/Forms/BankAccountMaintanence'
import {
  GUARANTEE_CASH_SECURITY_DEPOSIT,
  NEW_BG_CATEGORY,
} from 'components/InternalRequest/Forms/BankGuarantee'
import ApprovedPersonAndManualPaymentApprovers from 'components/InternalRequest/PrintTicketTemplate/ApprovedPersonAndManualPaymentApprovers'
import {
  MemoizedTableFixHeader,
  MemoizedBodyRow,
} from 'components/_Common/CustomizedFormTable/MemoizedComponent'
import './index.css'

const TableComponent = ({columns, data}) => {
  return (
    <table>
      <MemoizedTableFixHeader columns={columns} />
      <MemoizedBodyRow data={data} columns={columns} />
    </table>
  )
}

export default function PrintTicketTemplate({
  formDetails,
  user,
  printComments,
  comments,
  progressIndicator,
  progressSummary = [],
}) {
  const renderCommentTitle = () => {
    const commentLength = comments.length
    switch (commentLength) {
      case 0:
        return 'No comments'
      case 1:
        return '1 comment'
      default:
        return `${commentLength} comments`
    }
  }

  /**
   * To render the diff Maintenance form
   */
  const renderTypeOfChangeInput = (detail: Array, index: number) => {
    const typeOfChangeValue = detail.type_of_change

    switch (typeOfChangeValue) {
      case TYPE_OF_CHANGE_CREATION:
        return (
          <React.Fragment>
            <Label label={`Bank Account`} />
            <TableComponent
              columns={[
                {
                  name: 'Bank',
                  field: 'bank_id',
                  component: ({value}) => value?.code,
                },
                {
                  name: 'Bank Account Number',
                  field: 'account_number',
                },
                {
                  name: 'Account Type',
                  field: 'account_type',
                },
                {
                  name: 'Currency',
                  field: 'currency_code',
                },
                {
                  name: 'Managed By',
                  field: 'acc_managed_by',
                },
                {
                  name: 'New Role(To Be)',
                  field: 'role',
                  component: ({value}) => value?.text,
                },
                {
                  name: 'New Grouping(To Be)',
                  field: 'group',
                },
              ]}
              data={detail.bank_details}
            />
            <Label label={`Employee Details`} />
            <TableComponent
              columns={[
                {
                  name: 'Reason',
                  width: '25%',
                  field: 'reason',
                },
                {
                  name: 'Name',
                  field: 'fullname',
                  width: '40%',
                  component: ({value}) =>
                    value
                      ? `${value.full_name} ${value.email ? `(${value.email})` : ''}`
                      : '',
                },
                {
                  name: 'Company Designation',
                  field: 'company_designation',
                },
              ]}
              data={detail.emp_details}
            />
          </React.Fragment>
        )
      case TYPE_OF_CHANGE_DELETION:
        return (
          <React.Fragment>
            <Label label={`Bank Account`} />
            <TableComponent
              columns={[
                {
                  name: 'Bank',
                  field: 'bank_id',
                  component: ({value}) => value?.code,
                },
                {
                  name: 'Bank Account Number',
                  field: 'account_number',
                },
                {
                  name: 'Account Type',
                  field: 'account_type',
                },
                {
                  name: 'Currency',
                  field: 'currency_code',
                },
                {
                  name: 'Managed By',
                  field: 'acc_managed_by',
                },
              ]}
              data={detail.bank_details}
            />
            <Label label={`Employee Details`} />
            <TableComponent
              columns={[
                {
                  name: 'Reason',
                  field: 'reason',
                },
                {
                  name: 'Name',
                  field: 'fullname',
                  component: ({value}) =>
                    value
                      ? `${value.full_name} ${value.email ? `(${value.email})` : ''}`
                      : '',
                },
                {
                  name: 'Company Designation',
                  field: 'company_designation',
                },
                {
                  name: 'Existing Role(To Remove)',
                  field: 'approve_type',
                  component: ({value, dataIndex, error}) => value?.text ?? value,
                },
              ]}
              data={detail.emp_details}
            />
          </React.Fragment>
        )

      case TYPE_OF_CHANGE_USER_ROLE_UPDATE:
        return (
          <React.Fragment>
            <Label label={`Bank Account`} />
            <TableComponent
              columns={[
                {
                  name: 'Bank',
                  field: 'bank_id',
                  component: ({value}) => value?.code,
                },
                {
                  name: 'Bank Account Number',
                  field: 'account_number',
                },
                {
                  name: 'Currency',
                  field: 'currency_code',
                },
                {
                  name: 'Managed By',
                  field: 'acc_managed_by',
                },
                {
                  name: 'New Role(To Be)',
                  field: 'role',
                  component: ({value, dataIndex, error}) => value?.text ?? value,
                },
                {
                  name: 'New Grouping(To Be)',
                  field: 'group',
                  component: ({value, dataIndex, error}) => value?.value ?? value,
                },
              ]}
              data={detail.bank_details}
            />
            <Label label={`Employee Details`} />
            <TableComponent
              columns={[
                {
                  name: 'Reason',
                  field: 'reason',
                },
                {
                  name: 'Name',
                  field: 'fullname',
                  component: ({value}) =>
                    value
                      ? `${value.full_name} ${value.email ? `(${value.email})` : ''}`
                      : '',
                },
                {
                  name: 'Company Designation',
                  field: 'company_designation',
                },
                {
                  name: 'Existing Role(To Replace)',
                  field: 'approve_type',
                  component: ({value, dataIndex}) => value?.text ?? value,
                },
              ]}
              data={detail.emp_details}
            />
          </React.Fragment>
        )

      case TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE:
        return (
          <React.Fragment>
            <Label label={`Bank Account`} />
            <TableComponent
              columns={[
                {
                  name: 'Bank',
                  field: 'bank_id',
                  component: ({value}) => value?.code,
                },
                {
                  name: 'Bank Account Number',
                  field: 'account_number',
                },
                {
                  name: 'Account Type',
                  field: 'account_type',
                },
                {
                  name: 'Currency',
                  field: 'currency_code',
                },
                {
                  name: 'Managed By',
                  field: 'acc_managed_by',
                },
              ]}
              data={detail.bank_details}
            />
            <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
              Details
            </Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <TextField label="Reason For Change" value={detail.reason_for_change} />
              </Grid>
              <Grid item xs={12}>
                {renderMockTextArea(
                  'New System Admin Setup',
                  detail.new_system_admin_setup
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        )

      case TYPE_OF_CHANGE_AUTHORIZED_PERSON_UPDATE:
        return (
          <React.Fragment>
            <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
              Details
            </Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <ApprovedPersonAndManualPaymentApprovers
                  details={detail}
                  showApprovedPerson={true}
                  showManualApproval={false}
                  renderMockTextArea={renderMockTextArea}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )
      case TYPE_OF_CHANGE_MANUAL_PAYMENTS_UPDATE:
        return (
          <React.Fragment>
            <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
              Details
            </Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <ApprovedPersonAndManualPaymentApprovers
                  showManualOfflineApproval={true}
                  showApprovedPerson={false}
                  showManualApproval={false}
                  details={detail}
                  renderMockTextArea={renderMockTextArea}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )

      case TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE:
        return (
          <React.Fragment>
            <Label label={`Bank Account`} />
            <TableComponent
              columns={[
                {
                  name: 'Bank',
                  field: 'bank_id',
                  component: ({value}) => value?.code,
                },
                {
                  name: 'Bank Account Number',
                  field: 'account_number',
                },
                {
                  name: 'Account Type',
                  field: 'account_type',
                },
                {
                  name: 'Currency',
                  field: 'currency_code',
                },
                {
                  name: 'Managed By',
                  field: 'acc_managed_by',
                },
              ]}
              data={detail.bank_details}
            />
            <Label label={`Matrix`} required={true} error={detail.error_matrix} />
            <TableComponent
              columns={[
                {
                  name: 'Matrix Threshold',
                  field: 'matrix_threshold',
                  component: ({value, dataIndex, error}) => value?.text ?? value,
                },
                {
                  name: 'Matrix Condition',
                  field: 'matrix_condition',
                  component: ({value, dataIndex, error}) => value?.text ?? value,
                },
              ]}
              data={detail.matrix_condition}
            />
          </React.Fragment>
        )

      case TYPE_OF_CHANGE_BANK_ACCOUNT_NUMBER_UPDATE:
        return (
          <React.Fragment>
            <Label label={`Bank Account`} />
            <TableComponent
              columns={[
                {
                  name: 'Bank',
                  field: 'bank_id',
                  component: ({value}) => value?.code,
                },
                {
                  name: 'Bank Account Number',
                  field: 'account_number',
                },
                {
                  name: 'Account Type',
                  field: 'account_type',
                },
                {
                  name: 'Currency',
                  field: 'currency_code',
                },
                {
                  name: 'Managed By',
                  field: 'acc_managed_by',
                },
              ]}
              data={detail.bank_details}
            />
            <br />
            <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
              New Bank Account Number Info
            </Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <TextField
                  label="New Bank Account Number"
                  value={detail.new_bank_account_number}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Reason For Change" value={detail.reason_for_change} />
              </Grid>
            </Grid>
          </React.Fragment>
        )

      case TYPE_OF_CHANGE_ACC_MANAGED_BY_SUB_CAT_UPDATE:
        return (
          <React.Fragment>
            <Label label={`Bank Account`} />
            <TableComponent
              columns={[
                {
                  name: 'Bank',
                  field: 'bank_id',
                  component: ({value}) => value?.code,
                },
                {
                  name: 'Bank Account Number',
                  field: 'account_number',
                },
                {
                  name: 'Currency',
                  field: 'currency_code',
                },
                {
                  name: 'New Managed By (To Be)',
                  field: 'acc_managed_by',
                },
                {
                  name: 'New Sub-Category (To Be)',
                  field: 'account_type',
                },
              ]}
              data={detail.bank_details}
            />
            <br />
            <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
              Details
            </Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <TextField label="Reason For Change" value={detail.reason_for_change} />
              </Grid>
            </Grid>
          </React.Fragment>
        )

      default:
        return <></>
    }
  }

  /**
   * To render the different form typef
   * @return {JSX.Element|string}
   */
  const renderFormTypeRequest = () => {
    switch (formDetails.category_type?.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        return (
          <React.Fragment>
            <Typography variant={'subtitle2'}>Bank Account Opening Form</Typography>
            {formDetails.form_details?.map((detail, index) => {
              return (
                <React.Fragment key={`bank-account-opening-${index}`}>
                  <Typography variant={'caption'}>Form {index + 1}</Typography>
                  <Grid
                    container
                    justifyContent={'flex-start'}
                    columnSpacing={6}
                    rowSpacing={2}
                    sx={{pb: 3}}
                  >
                    <Grid item xs={12}>
                      <TextField label="Bank" value={detail.bank_id?.text} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Currency"
                        value={formDetails?.currency?.text ?? 'All'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Number of account to open"
                        value={detail.number_of_account_to_open}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {renderMockTextArea(
                        'Purpose of bank account',
                        detail.purpose_of_bank_account
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <RadioButton
                        label="Open with preferred bank"
                        value={detail.open_with_preferred_bank}
                        options={['Y', 'N']}
                      />
                    </Grid>
                    {detail.open_with_preferred_bank === 'N' && (
                      <Grid item xs={12}>
                        {renderMockTextArea(
                          'Please Explain',
                          detail.open_with_preferred_bank_explanation
                        )}
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <RadioButton
                        label="Applying MT940?"
                        value={detail.applying_MT940}
                        options={['Y', 'N']}
                      />
                    </Grid>
                    {detail.applying_MT940?.toUpperCase() === 'N' && (
                      <Grid item xs={12}>
                        {renderMockTextArea(
                          'Please Explain',
                          detail.applying_MT940_Explanation
                        )}
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <RadioButton
                        label="Cheque Book Required?"
                        value={detail.cheque_book_required}
                        options={['Y', 'N']}
                      />
                    </Grid>
                    {detail.cheque_book_required?.toUpperCase() === 'Y' && (
                      <Grid item xs={12}>
                        {renderMockTextArea(
                          'Please Explain',
                          detail.cheque_book_required_explanation
                        )}
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        label="Account managed by"
                        value={detail.acc_managed_by?.text ?? detail.acc_managed_by}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField label="Sub Category" value={detail.sub_category} />
                    </Grid>
                    {((detail.acc_managed_by?.value?.toLowerCase() === 'finops' &&
                      formDetails.sub_category_1 === 'WC') ||
                      (detail.acc_managed_by?.value?.toLowerCase() === 'finance' &&
                        formDetails.sub_category_1 === 'ESCROW')) && (
                      <Grid item xs={12}>
                        {renderMockTextArea(
                          'Please Explain',
                          detail.account_managed_by_explanation
                        )}
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              )
            })}
            <Box className={'page-break'} />
            <Typography variant={'subtitle2'}>Operation Details</Typography>
            <ApprovedPersonAndManualPaymentApprovers
              details={formDetails}
              acc_opening_approved_person={'acc_opening_approved_person'}
              renderMockTextArea={renderMockTextArea}
            />
            <Box className={'page-break'} />
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={4}>
                <RadioButton
                  label="Online Banking"
                  value={formDetails.acc_opening_online_banking}
                  options={['Y', 'N']}
                />
              </Grid>
            </Grid>
            {formDetails.acc_opening_online_banking?.toLowerCase() === 'y' && (
              <React.Fragment>
                <Typography variant={'subtitle2'}>
                  Additional Information for Online Banking
                </Typography>
                <Grid
                  container
                  justifyContent={'flex-start'}
                  columnSpacing={6}
                  rowSpacing={2}
                  sx={{pb: 3}}
                >
                  <Grid item xs={12}>
                    <Label label={'Matrix'} />
                    <TableComponent
                      columns={[
                        {
                          name: 'Currency',
                          width: '10%',
                          component: () => formDetails.currency?.value,
                        },
                        {
                          name: 'Matrix Threshold',
                          field: 'matrix_threshold',
                          component: ({value}) => value,
                        },
                        {
                          name: 'Matrix Condition',
                          field: 'matrix_condition',
                          component: ({value}) => value,
                        },
                      ]}
                      data={formDetails.matrix_condition}
                    />
                  </Grid>
                  {[
                    {label: 'Approver', field: 'ob_approver', required: false},
                    {label: 'Maker', field: 'ob_maker', required: false},
                    {label: 'Verifier', field: 'ob_verifier', required: false},
                    {label: 'System Admin', field: 'system_admin', required: false},
                    {label: 'View Only', field: 'view_only', required: false},
                  ].map(({label, field, required}, key) => {
                    return (
                      <Grid item xs={12} sm={12} md={12} lg={12} key={`${key}-${field}`}>
                        <Label label={label} />
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TableComponent
                            columns={[
                              {
                                name: 'Name (Email)',
                                field: 'name',
                                width: '40%',
                                component: ({value, dataIndex, error, ...props}) =>
                                  value?.full_name
                                    ? `${value?.full_name} (${value?.email})`
                                    : '',
                              },
                              {
                                name: 'Title',
                                field: 'title',
                                width: '40%',
                                component: ({value}) => value,
                              },
                              {
                                ...(field === 'ob_approver' && {
                                  name: 'Group',
                                  field: 'group',
                                  disabled: field !== 'ob_approver',
                                  component: ({value}) => value,
                                }),
                              },
                            ]}
                            data={formDetails[field]}
                          />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </React.Fragment>
            )}
            {formDetails.online_banking === 'Y' && <Box className={'page-break'} />}
            {!['Draft', 'Withdrawn'].includes(formDetails.ticket_status) && (
              <React.Fragment>
                <Typography variant={'subtitle2'}>Account Status</Typography>
                <Grid
                  container
                  justifyContent={'flex-start'}
                  columnSpacing={6}
                  rowSpacing={2}
                  sx={{pb: 3}}
                >
                  <Grid item xs={12}>
                    <TableComponent
                      columns={[
                        {
                          name: 'Account Number',
                          width: '25%',
                          field: 'account_number',
                          component: ({value}) => value,
                        },
                        {
                          name: 'Entity',
                          width: '10%',
                          field: 'entity_desc',
                        },
                        {
                          name: 'Account Type',
                          width: '80px',
                          field: 'acc_type',
                        },
                        {
                          name: 'Bank',
                          width: '25%',
                          field: 'bank_description',
                        },
                        {
                          name: 'Account Managed By',
                          field: 'acc_managed_by',
                        },
                        {
                          name: 'Status',
                          width: '150px',
                          field: 'status',
                          dependentValue: 'account_number',
                        },
                      ]}
                      data={formDetails.acc_opening_status.sort((a, b) => {
                        const bankA = a.bank_description.toUpperCase() // ignore upper and lowercase
                        const bankB = b.bank_description.toUpperCase() // ignore upper and lowercase

                        const entityA = a.entity_desc?.toUpperCase() // ignore upper and lowercase
                        const entityB = b.entity_desc?.toUpperCase() // ignore upper and lowercase

                        if (bankA > bankB || entityA > entityB) {
                          return 1
                        }

                        if (bankA < bankB || entityA < entityB) {
                          return -1
                        }
                      })}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        )
      case FORM_BANK_ACCOUNT_CLOSING:
        return (
          <React.Fragment>
            <Typography variant={'subtitle2'}>Bank Account Closing Form</Typography>
            {formDetails.form_details?.map((detail, index) => {
              return (
                <React.Fragment key={`bank-account-closing-${index}`}>
                  <Typography variant={'caption'}>Form {index + 1}</Typography>
                  <Grid
                    container
                    justifyContent={'flex-start'}
                    columnSpacing={6}
                    rowSpacing={2}
                    sx={{pb: 3}}
                  >
                    <Grid item xs={6}>
                      <TextField label="Sub Category" value={detail.sub_category} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField label="Bank Account" value={detail.account_number} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="Entity Name" value={detail.entity_name} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Currency"
                        value={detail.currency_code?.text ?? detail.currency_code}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="Bank" value={detail.bank_id?.bank_description} />
                    </Grid>
                    <Grid item xs={12}>
                      {renderMockTextArea(
                        'Purpose of bank account',
                        detail.purpose_of_bank_acc
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {renderMockTextArea('Reason for closure', detail.reason_closure)}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'caption'}>
                        Bank Account details to transfer remaining cash balance
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Bank Account To Transfer"
                        value={detail.acc_no_to_transfer?.bank_account_number}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Entity Name"
                        value={detail.entity_to_transfer?.entity_name}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Amount to transfer"
                        value={detail.amount_to_transfer}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            })}

            {!['Draft', 'Withdrawn'].includes(formDetails.ticket_status) && (
              <React.Fragment>
                <Typography variant={'subtitle2'}>Account Status</Typography>
                <Grid
                  container
                  justifyContent={'flex-start'}
                  columnSpacing={6}
                  rowSpacing={2}
                  sx={{pb: 3}}
                >
                  <Grid item xs={12}>
                    <TableComponent
                      columns={[
                        {
                          name: 'Account Number',
                          width: '12%',
                          field: 'account_number',
                        },
                        {
                          name: 'Entity',
                          field: 'entity_name',
                        },
                        {
                          name: 'Account Type',
                          width: '80px',
                          field: 'sub_category',
                        },
                        {
                          name: 'Status',
                          width: '10%',
                          field: 'account_status',
                        },
                        {
                          name: 'Local To Confirm',
                          width: '10%',
                          field: 'local_to_confirm',
                        },
                        {
                          name: 'Remarks',
                          field: 'remarks',
                        },
                        {
                          name: 'Attachment',
                          width: '80px',
                          field: 'attachment',
                          component: ({value}) => value?.count,
                        },
                        {
                          name: 'Notify',
                          field: 'notify',
                          width: '100px',
                        },
                      ]}
                      data={formDetails.form_details}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        )
      case FORM_BANK_ACCOUNT_MAINTENANCE:
        return (
          <React.Fragment>
            <Typography variant={'subtitle2'}>Bank Account Maintenance Form</Typography>
            {formDetails.form_details?.map((detail, index) => {
              return (
                <React.Fragment key={`bank-account-opening-${index}`}>
                  <Typography variant={'caption'}>Form {index + 1}</Typography>
                  <Grid
                    container
                    justifyContent={'flex-start'}
                    columnSpacing={6}
                    rowSpacing={2}
                    sx={{pb: 3}}
                  >
                    <Grid item xs={12}>
                      <TextField label="Type Of Change" value={detail.type_of_change} />
                    </Grid>
                    {[
                      TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE,
                      TYPE_OF_CHANGE_AUTHORIZED_PERSON_UPDATE,
                      TYPE_OF_CHANGE_MANUAL_PAYMENTS_UPDATE,
                    ].includes(detail.type_of_change) && (
                      <Grid item xs={12}>
                        <TextField
                          label="reason"
                          value={detail.reason?.text ?? detail.reason}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {renderTypeOfChangeInput(detail, index)}
                  {formDetails.form_details.length !== index + 1 && (
                    <Box className={'page-break'} />
                  )}
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )
      case FORM_BANK_GUARANTEE:
        return (
          <React.Fragment>
            <Typography variant={'subtitle2'}>Bank Guarantee Form</Typography>
            <Typography variant={'caption'}>General</Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <TextField
                  label="Bank"
                  value={
                    formDetails.form_details?.bank_id?.text ??
                    formDetails.form_details?.bank_id
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Facility"
                  value={formDetails.form_details?.type_of_facility}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Guarantee"
                  value={formDetails.form_details?.type_of_guarantee}
                />
              </Grid>
              {formDetails.sub_category_1 !== NEW_BG_CATEGORY && (
                <Grid item xs={12}>
                  <TextField
                    label="Previous Ticket ID"
                    value={formDetails.form_details?.previous_ticket}
                  />
                </Grid>
              )}
              {formDetails.form_details?.type_of_guarantee ===
                GUARANTEE_CASH_SECURITY_DEPOSIT && (
                <Grid item xs={12}>
                  {renderMockTextArea(
                    'Reason for exception handling',
                    formDetails.form_details?.reason
                  )}
                </Grid>
              )}
            </Grid>
            <Typography variant={'caption'}>Beneficiary</Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  value={formDetails.form_details?.beneficiary_name}
                />
              </Grid>{' '}
              <Grid item xs={12}>
                <TextField
                  label="Contact"
                  value={formDetails.form_details?.beneficiary_contact}
                />
              </Grid>
              <Grid item xs={12}>
                {renderMockTextArea(
                  'Address',
                  formDetails.form_details?.beneficiary_address
                )}
              </Grid>
            </Grid>
            <Typography variant={'caption'}>Kyriba Information</Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={6}>
                <TextField
                  label="BG Ref Number (for current application)"
                  value={formDetails.form_details?.kyriba_bg_ref_no}
                />
              </Grid>
              {formDetails.sub_category_1 !== NEW_BG_CATEGORY && (
                <Grid item xs={6}>
                  <TextField
                    label="Previous Ref Number (For renewal)"
                    value={formDetails.form_details?.kyriba_previous_ref_no}
                  />
                </Grid>
              )}
            </Grid>
            <Typography variant={'caption'}>Bank Guarantee Details</Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <TextField
                  label="Purpose"
                  value={formDetails.form_details?.purpose_of_guarantee}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Amount"
                  value={formDetails.form_details?.guarantee_amount}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Currency"
                  value={formDetails.form_details?.currency_code}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Start/Effective Date"
                  value={
                    formDetails.form_details?.start_effective_date
                      ? moment(formDetails.form_details?.start_effective_date).format(
                          'yyyy-MM-DD'
                        )
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Expiry Date"
                  value={
                    formDetails.form_details?.expiry_date
                      ? moment(formDetails.form_details?.expiry_date).format('yyyy-MM-DD')
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Final Claim Date"
                  value={
                    formDetails.form_details?.final_claim_date
                      ? moment(formDetails.form_details?.final_claim_date).format(
                          'yyyy-MM-DD'
                        )
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date Guarantee Required"
                  value={
                    formDetails.form_details?.guarantee_required_date
                      ? moment(formDetails.form_details?.guarantee_required_date).format(
                          'yyyy-MM-DD'
                        )
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Format"
                  value={
                    formDetails.form_details?.format_of_guarantee?.text ??
                    formDetails.form_details?.format_of_guarantee
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Delivery Method"
                  value={
                    formDetails.form_details?.delivery_method?.text ??
                    formDetails.form_details?.delivery_method
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {renderMockTextArea(
                  'Other requirements',
                  formDetails.form_details?.other_requirement
                )}
              </Grid>
              <Grid item xs={4}>
                <RadioButton
                  label="Collateral Required"
                  value={formDetails.form_details?.collateral_required?.toUpperCase()}
                  options={['Y', 'N']}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Collateral Amount"
                  value={formDetails.form_details?.collateral_amount}
                />
              </Grid>
              <Grid item xs={4}>
                <RadioButton
                  label="Is Auto-renewable applicable"
                  value={formDetails.form_details?.auto_renewal_applicable.toUpperCase()}
                  options={['Y', 'N']}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Commission Charge"
                  value={formDetails.form_details?.commission_charge_amount}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Other issuance costs"
                  value={formDetails.form_details?.other_issuance_amount}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )
      case FORM_DOCUMENT_SIGNING_REQUEST:
        return (
          <React.Fragment>
            <Typography variant={'subtitle2'}>Document Signing Request Form</Typography>
            <Grid
              container
              justifyContent={'flex-start'}
              columnSpacing={6}
              rowSpacing={2}
              sx={{pb: 3}}
            >
              <Grid item xs={12}>
                <TextField
                  label="Bank"
                  value={
                    formDetails.form_details?.doc_signing_bank?.text ??
                    formDetails.form_details?.doc_signing_bank
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {renderMockTextArea(
                  'Purpose for Signing Request',
                  formDetails.form_details?.doc_signing_purpose
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        )
      default:
        return 'no'
    }
  }

  const renderMockTextArea = (label, value) => {
    return (
      <>
        <Label label={label} />
        <Typography
          sx={{color: '#6B6B6B', overflowWrap: 'break-word'}}
          dangerouslySetInnerHTML={{
            __html: value?.replace(/\n/g, '<br/>') ?? `<p> </p>`,
          }}
        />
        <Divider
          sx={{
            borderColor: 'rgba(0, 0, 0, 0.42)!important',
            borderBottomWidth: '1px',
          }}
        />
      </>
    )
  }

  return (
    <div className={'print-ticket-template'}>
      <Typography variant={'h6'} gutterBottom sx={{fontWeight: 'bolder'}}>
        {APP_NAME}
      </Typography>

      <Typography variant={'subtitle2'} sx={{fontWeight: 'bolder'}}>
        Internal Request Form
      </Typography>

      <Grid
        container
        justifyContent={'flex-start'}
        columnSpacing={6}
        rowSpacing={2}
        sx={{pb: 3}}
      >
        <Grid item xs={6}>
          <TextField value={user.name} label="Printed By" />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Printed Date" value={moment().format(dateTimeFormat)} />
        </Grid>
      </Grid>

      <Typography variant={'subtitle2'} sx={{fontWeight: 'bolder'}}>
        General
      </Typography>
      <Grid
        container
        justifyContent={'flex-start'}
        columnSpacing={8}
        rowSpacing={2}
        sx={{pb: 3}}
      >
        <Grid item xs={6}>
          <TextField label="Ticket ID" value={formDetails.ticket_number} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Category" value={formDetails.category_type} />
        </Grid>
        <Grid item xs={12}>
          {renderMockTextArea(
            'Entity',
            formDetails?.entity_id.map(({entity_code}) => entity_code).toString()
          )}
        </Grid>
        <Grid item xs={12}>
          {renderMockTextArea(
            'Country',
            formDetails?.country_id.map(({country_id}) => country_id)?.toString()
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField label="Sub Category" value={formDetails.sub_category_1} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Status" value={formDetails.ticket_status} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Requested At" value={formDetails.submission_date_format} />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last Updated Date"
            value={moment(formDetails.last_update_date).format('DD/MM/YYYY')}
          />
        </Grid>
      </Grid>
      <>
        <Typography variant={'subtitle2'} sx={{fontWeight: 'bolder'}}>
          Details
        </Typography>
        <Typography variant={'caption'}>Information</Typography>
        <Grid
          container
          justifyContent={'flex-start'}
          columnSpacing={8}
          rowSpacing={2}
          sx={{pb: 3}}
        >
          <Grid item xs={12}>
            <TextField label="Requester" value={formDetails.request_by_fullname} />
          </Grid>
          <Grid item xs={12}>
            {renderMockTextArea('Request Summary', formDetails?.request_summary)}
          </Grid>
        </Grid>
        <Box className={'page-break'} />
        <Typography variant={'subtitle2'}>Approval Path</Typography>
        <Typography variant={'caption'}>Local</Typography>
        <Grid
          container
          justifyContent={'flex-start'}
          columnSpacing={8}
          rowSpacing={2}
          sx={{pb: 3}}
        >
          <Grid item xs={12}>
            <Label label={`Notifiers (${formDetails.local_notify.length})`} />
            <TableComponent
              noDataLabel={'No Notifier'}
              columns={[
                {
                  field: 'full_name',
                  name: 'Name',
                  width: '40%',
                },
                {
                  field: 'email',
                  name: 'Email',
                  width: '40%',
                },
                {
                  field: 'approve_date',
                  name: 'Status',
                  component: ({value}) => (value ? 'Notified' : 'Pending'),
                },
                {
                  field: 'approve_date',
                  name: 'TimeStamp',
                  component: ({value}) => {
                    return value ? moment(value).format(dateTimeFormatTable) : ''
                  },
                },
              ]}
              data={formDetails.local_notify}
            />
            <Grid item xs={12}>
              <Label label={`Approvers (${formDetails.local_approval.length})`} />
              <TableComponent
                columns={[
                  {
                    field: 'full_name',
                    name: 'Name',
                    width: '40%',
                  },
                  {
                    field: 'email',
                    name: 'Email',
                    width: '40%',
                  },
                  {
                    field: 'approve_date',
                    name: 'Status',
                    component: ({value, data}) => {
                      return value
                        ? 'Approved'
                        : formDetails.ticket_status === 'Rejected' &&
                          formDetails.status.includes('Approval Rejected')
                        ? formDetails.reject_by === data?.email
                          ? 'Rejected'
                          : data?.new_approver
                          ? 'Pending'
                          : 'Notified Rejected'
                        : 'Pending'
                    },
                  },
                  {
                    field: 'approve_date',
                    name: 'TimeStamp',
                    component: ({value}) => {
                      return value ? moment(value).format(dateTimeFormatTable) : ''
                    },
                  },
                ]}
                data={formDetails.local_approval}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box className={'page-break'} />
        <Typography variant={'subtitle2'}>HQ</Typography>
        <Grid
          container
          justifyContent={'flex-start'}
          columnSpacing={8}
          rowSpacing={2}
          sx={{pb: 3}}
        >
          <Grid item xs={12}>
            <TextField
              label="Verifier 1"
              value={formDetails.hq_verifier_1_fullname ?? 'HQ Treasury'}
              {...(formDetails.hq_verifier_1_approve_date && {
                InputProps: {
                  endAdornment: <Chip size={'small'} label={'Verified'} />,
                },
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Verifier 2"
              value={
                formDetails.hq_verifier_2?.full_name
                  ? `${formDetails.hq_verifier_2?.full_name} (${formDetails.hq_verifier_2?.email})`
                  : ''
              }
              {...(formDetails.hq_verifier_2_approve_date && {
                InputProps: {
                  endAdornment: <Chip size={'small'} label={'Verified'} />,
                },
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Label label={`Approvers (${formDetails.local_approval.length})`} />
            <TableComponent
              columns={[
                {
                  field: 'full_name',
                  name: 'Name',
                  width: '40%',
                },
                {
                  field: 'email',
                  name: 'Email',
                  width: '40%',
                },
                {
                  field: 'approve_date',
                  name: 'Status',
                  component: ({value, data}) => {
                    return value
                      ? 'Approved'
                      : formDetails.ticket_status === 'Rejected' &&
                        formDetails.status.includes('Approval Rejected')
                      ? formDetails.reject_by === data?.email
                        ? 'Rejected'
                        : data?.new_approver
                        ? 'Pending'
                        : 'Notified Rejected'
                      : 'Pending'
                  },
                },
                {
                  field: 'approve_date',
                  name: 'TimeStamp',
                  component: ({value}) => {
                    return value ? moment(value).format(dateTimeFormatTable) : ''
                  },
                },
              ]}
              data={formDetails.hq_approval}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Final Approver"
              value={
                formDetails.hq_final_approver
                  ? `${formDetails.hq_final_approver.full_name} (${formDetails.hq_final_approver.email})`
                  : ''
              }
              {...((formDetails.hq_final_approver?.approve_date ||
                formDetails.reject_by === formDetails.hq_approval?.email) && {
                InputProps: {
                  endAdornment: (
                    <Chip
                      size={'small'}
                      label={
                        formDetails.hq_final_approver?.approve_date
                          ? 'Approved'
                          : formDetails.ticket_status === 'Rejected' &&
                            formDetails.reject_by === user.email &&
                            'Rejected'
                      }
                    />
                  ),
                },
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Final Notification"
              value={formDetails.hq_final_approver_approve_date ? 'Notified' : ''}
            />
          </Grid>
        </Grid>
      </>

      <Box className={'page-break'} />

      {renderFormTypeRequest()}

      <Box sx={{pb: 3}} className={'page-break'}>
        <>
          <Typography variant={'subtitle2'} sx={{fontWeight: 'bolder'}}>
            Progress Indicator
          </Typography>
          {formDetails.ticket_status === 'Withdrawn' ? (
            'Ticket have been withdrawn so this is N/A'
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Action by</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {progressIndicator?.map(
                  (
                    {
                      step_name,
                      user_email,
                      full_name,
                      delegate,
                      delegate_full_name,
                      approve_date,
                    },
                    index
                  ) => {
                    return (
                      <tr key={`${index}-progress-indicator`}>
                        <td>
                          <Typography variant={'body2'}>{step_name}</Typography>
                        </td>
                        <td>
                          <Typography variant={'body2'}>
                            {`${full_name ? `${full_name} (${user_email})` : ''}`}
                          </Typography>
                        </td>
                        <td>
                          <Typography variant={'body2'}>
                            {approve_date
                              ? moment.utc(approve_date).local().format(dateTimeFormat)
                              : ''}
                          </Typography>
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </table>
          )}
        </>
      </Box>

      <Box sx={{pb: 3}} className={'page-break'}>
        <Typography variant={'subtitle2'} sx={{fontWeight: 'bolder'}}>
          Ticket History
        </Typography>
        <table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Action by</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {progressSummary?.map(
              (
                {
                  full_name,
                  user_email,
                  delegate,
                  delegate_full_name,
                  action_date,
                  status,
                },
                index
              ) => {
                return (
                  <tr key={`${index}-index-progress-summary`}>
                    <td>
                      <Typography variant={'body2'}>{status}</Typography>
                    </td>
                    <td>
                      <Typography variant={'body2'}>
                        {full_name} ({user_email})
                        {delegate_full_name &&
                          ` on behalf of ${delegate_full_name} (${delegate}) `}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant={'body2'}>
                        {moment.utc(action_date).local().format(dateTimeFormat)}
                      </Typography>
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </Box>

      <Box sx={{pb: 3}} className={'page-break'}>
        <Typography variant={'subtitle2'} sx={{fontWeight: 'bolder'}}>
          Attachments
        </Typography>
        {formDetails.ticket_attachment.length === 0 ? (
          <Typography variant={'caption'}>0 Attachments</Typography>
        ) : (
          <>
            <Typography variant={'caption'}>
              {formDetails.ticket_attachment.length} Attachment
              {formDetails.ticket_attachment.length > 1 ? 's' : ''}
            </Typography>

            <table key={'attachment-table'}>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Document Name</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                {formDetails.ticket_attachment.map(({name, docName, purpose}, index) => {
                  return (
                    <tr key={`${index}-index-attachments`}>
                      <td>
                        <Typography variant={'body2'}>{index + 1}</Typography>
                      </td>
                      <td>
                        <Typography variant={'body2'}>{name}</Typography>
                      </td>
                      <td>{docName}</td>
                      <td>{purpose}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
      </Box>

      {printComments && (
        <>
          <Typography variant={'subtitle2'} sx={{fontWeight: 'bolder'}}>
            Comments ({comments.length} comment{comments.length > 1 ? 's' : ''})
          </Typography>
          <Typography variant={'caption'}>{renderCommentTitle()}</Typography>
          <Grid
            container
            justifyContent={'flex-start'}
            columnSpacing={8}
            rowSpacing={2}
            sx={{pb: 3}}
          >
            {comments.map(({full_name, comment_date, status, comments}, index) => {
              return (
                <Grid item xs={12} key={`comment-${index}`}>
                  <div className="comment" id={`comment-${index}`}>
                    <Typography variant={'body2'}>
                      {full_name} - {moment(comment_date).format(dateTimeFormatTable)}
                    </Typography>
                    <Typography variant={'caption'}>({status})</Typography>
                    <Typography
                      variant={'body2'}
                      dangerouslySetInnerHTML={{__html: comments}}
                    />
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </div>
  )
}
