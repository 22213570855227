import {encode} from 'html-entities'

import TSApi from 'api/TS/TSApi'
import type {DefaultSelectType, FetchUserType} from 'api/TS/query/_CommonQueries'
import {
  FORM_BANK_ACCOUNT_CLOSING,
  FORM_BANK_ACCOUNT_MAINTENANCE,
  FORM_BANK_ACCOUNT_OPENING,
  FORM_BANK_GUARANTEE,
  FORM_DOCUMENT_SIGNING_REQUEST,
  timeZone,
} from '../../../_helper/_constants'
import EmployeeListForBankAccount from 'components/InternalRequest/Forms/_Common/EmployeeListForBankAccount'

export type CategoriesType = {
  category_id: number,
  category_type: string,
  sub_category_1: string,
}
/**
 * To retrieve the categories for new internal request
 * @return {Promise<CategoriesType>}
 */
export async function fetchNewRequestCategories(): CategoriesType {
  const {data} = await TSApi.get(`/category/`)

  return data
}

type FetchInternalRequestType = {
  count: number,
  next: string,
  previous: string,
  results: Array<{
    acc_opening_approved_person: string,
    acc_opening_manual_payment_approvers_limit: string,
    acc_opening_matrix_condition: string,
    acc_opening_matrix_currency: string,
    acc_opening_matrix_threshold: string,
    acc_opening_online_banking: string,
    category_id: number,
    category_type: string,
    creation_date: string,
    current_order: string,
    doc_signing_bank: string,
    doc_signing_purpose: string,
    entity_id: string,
    last_update_date: string,
    last_updated_by: string,
    reject_by: string,
    request_by: string,
    request_summary: string,
    sub_category_1: string,
    submission_date_format: string,
    ticket_id: number,
    ticket_number: string,
    ticket_status: string,
  }>,
  total_pages: number,
}
/**
 * To retrieve internal request view
 * @param tab
 * @param filterQuery
 * @param limit
 * @param page
 * @return {Promise<FetchInternalRequestType>}
 */
export async function fetchInternalRequests(
  tab: string,
  filterQuery?: any,
  searchTF?: string,
  rowsPerPage?: number,
  page?: number
): FetchInternalRequestType {
  let filterQueryString = '',
    pageQuery = ''

  for (let [filter, value] of Object.entries(filterQuery)) {
    switch (filter) {
      case 'country':
        if (value.length === 0) {
          break
        }
        value.map(({country_id}) => {
          filterQueryString = filterQueryString.concat('&', filter, '=', country_id)
        })
        break
      case 'entity':
        if (value.length === 0) {
          break
        }
        value.map(({entity_id}) => {
          filterQueryString = filterQueryString.concat('&', filter, '=', entity_id)
        })
        break
      case 'category':
      case 'sub_category':
      case 'ticketstatus':
        if (value.length === 0) {
          break
        }
        value.map((v) => {
          filterQueryString = filterQueryString.concat(
            '&',
            filter,
            '=',
            encodeURIComponent(v)
          )
        })
        break
      default:
        if (value === null) {
          break
        }
        filterQueryString = filterQueryString.concat('&', filter, '=', value)
    }
  }

  if (searchTF) {
    filterQueryString = filterQueryString.concat('&', 'searchtext', '=', searchTF)
  }

  if (page > 0) {
    pageQuery = '&page=' + page
  }

  const {data} = await TSApi.get(
    `/summary_request_ticket/?tz=${timeZone}&limit=${rowsPerPage}&tab=${tab}${filterQueryString.trim()}${pageQuery}`
  )

  return data
}

/**
 * To retrieve internal request view filters
 * @param tab
 * @param filterQuery
 * @param limit
 * @param page
 * @return {Promise<FetchInternalRequestType>}
 */
export async function fetchInternalRequestsFilters(tab: string): {
  status: [],
  entities: {entity_id: number, entity_code: string, entity_name: string}[],
  countries: {country_id: number, country_name: string}[],
  category: [],
  sub_category: [],
} {
  const {data} = await TSApi.get(
    `/summary_request_ticket_filters/?tz=${timeZone}&tab=${tab}`
  )

  return data[0]
}

type CreateInternalRequestType = {
  result: string,
  ticket_id: number,
  ticket_number: string,
}
/**
 * To create new request
 * @param details
 * @return {Promise<CreateInternalRequestType>}
 */
export async function createNewInternalRequest(newRequest): CreateInternalRequestType {
  const {data} = await TSApi.put('/save_ticket/', {
    country: newRequest.country.value.map((value) => value.country_id),
    entity: newRequest.entity.value.map((value) => value.value),
    category_type: newRequest.category.value.value,
    sub_category: newRequest.subCategory?.value?.value,
  })

  return data
}

export type FetchRequestFormType = {
  category_id: number,
  category_type: string,
  current_orders: string,
  editable: string,
  entity_id: string,
  hq_approval: [],
  hq_final_approver: string,
  hq_final_approver_approve_date: string,
  hq_final_approver_fullname: string,
  hq_verifier_1: string,
  hq_verifier_1_approve_date: string,
  hq_verifier_1_fullname: string,
  hq_verifier_2: string,
  hq_verifier_2_approve_date: string,
  hq_verifier_2_fullname: string,
  last_update_date_format: string,
  last_updated_by: string,
  local_approval: [],
  local_notify: [],
  request_by: string,
  request_by_fullname: string,
  request_summary: string,
  sub_category_1: string,
  submission_date_format: string,
  ticket_id: number,
  ticket_number: string,
  ticket_status: string,
  reject_by: string,
  ticket_attachment: Array<{
    attachment_id: number,
    attachment_filename: string,
    attachment_purpose: string,
    document_name: string,
  }>,
  ticket_attachment_count: number,
}

/**
 * To retrieve internal request form
 * @param id
 * @return {Promise<FetchRequestFormType>}
 */
export async function fetchRequestForm(id): FetchRequestFormType {
  const {data} = await TSApi.get(`/request_edit_ticket/?ticket_id=${id}&tz=${timeZone}`)

  return data[0]
}

/** Prepare the payload for save/ submit internal request form
 *
 * @param request
 * @param type
 * @return {{hq_final_approver: *, sub_category: *, hq_approver: *, local_approver: *, category_type: *, hq_verifier_2: *, request_summary: *, ticket_id: *, entity: *, notify: *}}
 */
const retrieveRequestData = (request, type) => {
  let requestData = {
    ticket_id: request.ticket_id ?? null,
    country: request.country_id.map(({country_id}) => country_id),
    entity: request.entity_id.map(({entity_id}) => entity_id),
    category_type: request.category_type,
    sub_category: request.sub_category_1,
    request_summary: request.request_summary,
    notify: request.local_notify.map(({email}) => email),
    local_approver: request.local_approval.map(({email}) => email),
    hq_approver: request.hq_approval.map(({email}) => email),
    hq_verifier_2: request.hq_verifier_2?.email,
    hq_final_approver: request.hq_final_approver?.email,
    ticket_attachment:
      request.ticket_attachment?.length > 0
        ? request.ticket_attachment.map((data) => {
            return {
              purpose: data.purpose,
              document_name: data.docName,
              id: data.attachment_id,
            }
          })
        : [],
  }
  const {form_details} = request

  switch (request.category_type.replaceAll(' ', '')) {
    case FORM_BANK_ACCOUNT_OPENING:
      requestData.currency = request.currency?.value ?? null
      requestData.approved_person = request.acc_opening_approved_person
      requestData.manual_payment_approvers_limit =
        request.acc_opening_manual_payment_approvers_limit
      requestData.online_banking = request.acc_opening_online_banking?.toUpperCase()
      requestData.acc_opening_form_details =
        request.form_details?.length > 0
          ? request.form_details.map((data) => {
              return {
                account_managed_by: data.acc_managed_by ?? null,
                account_managed_by_explanation: data.acc_managed_by_explain,
                applying_MT940: data.applying_MT940,
                applying_MT940_Explanation: data.applying_MT940_Explanation,
                bank: data.bank_id ? data.bank_id.value : null,
                cheque_book_required: data.cheque_book_required,
                cheque_book_required_explanation: data.cheque_book_required_explanation,
                sub_category: data.sub_category ?? null,
                number_of_account_to_open: data.number_of_account_to_open ?? null,
                open_with_preferred_bank: data.open_with_preferred_bank ?? null,
                open_with_preferred_bank_explanation:
                  data.open_with_preferred_bank_explanation ?? null,
                purpose_of_bank_account: data.purpose_of_bank_account ?? null,
              }
            })
          : []
      requestData.maker =
        request.ob_maker?.length > 0
          ? request.ob_maker.map((data) => {
              return {
                acc_online_banking_id: data.acc_online_banking_id,
                name: JSON.stringify(data.name),
                title: data.title ?? null,
              }
            })
          : requestData.ob_maker
      requestData.verifier =
        request.ob_verifier?.length > 0
          ? request.ob_verifier.map((data) => {
              return {
                acc_online_banking_id: data.acc_online_banking_id,
                name: JSON.stringify(data.name),
                title: data.title ?? null,
              }
            })
          : requestData.ob_verifier
      requestData.approver =
        request.ob_approver?.length > 0
          ? request.ob_approver.map((data) => {
              return {
                acc_online_banking_id: data.acc_online_banking_id,
                name: JSON.stringify(data.name),
                title: data.title ?? null,
                group: data.group ?? null,
              }
            })
          : requestData.ob_approver
      requestData.system_admin =
        request.system_admin?.length > 0
          ? request.system_admin.map((data) => {
              return {
                acc_online_banking_id: data.accOnlineBankingId,
                name: JSON.stringify(data.name),
                title: data.title ?? null,
              }
            })
          : request.system_admin
      requestData.view_only =
        request.view_only?.length > 0
          ? request.view_only.map((data) => {
              return {
                acc_online_banking_id: data.accOnlineBankingId,
                name: JSON.stringify(data.name),
                title: data.title ?? null,
              }
            })
          : request.view_only
      requestData.online_banking_matrix =
        request.matrix_condition?.length > 0
          ? request.matrix_condition.map((data) => {
              return {
                matrix_threshold: data.matrix_threshold ?? null,
                matrix_condition: data.matrix_condition ?? null,
              }
            })
          : request.matrix_condition
      requestData.acc_opening_status = [5, 7].includes(request.current_order)
        ? request.acc_opening_status.map((data) => {
            return {
              opening_status_id: data.opening_status_id,
              account_number: data.account_number,
              status: data.account_number
                ? 'Active'
                : `Pending opening, requested on ${request.submission_date_format}`,
              bank_id: data.bank_id,
              acc_managed_by: data.acc_managed_by,
              currency_code: request.currency?.value,
              entity_id: data.entity_id,
              acc_type: data.acc_type,
            }
          })
        : undefined
      break
    case FORM_BANK_ACCOUNT_CLOSING:
      requestData.acc_closing_form_details =
        request.form_details?.length > 0
          ? request.form_details.map((data) => {
              return {
                bank: data.bank_id ? data.bank_id.bank_id : null,
                currency_code: data.currency_code ? data.currency_code.value : null,
                obm_account_id: data.obm_account_id ?? null,
                account_number: data.account_number,
                purpose_of_bank_acc: data.purpose_of_bank_acc,
                reason_closure: data.reason_closure,
                acc_no_to_transfer: data.acc_no_to_transfer
                  ? data.acc_no_to_transfer.bank_account_number
                  : null,
                entity_to_transfer: data.entity_to_transfer
                  ? data.entity_to_transfer.entity_name
                  : null,
                amount_to_transfer:
                  data.amount_to_transfer === '' ? null : data.amount_to_transfer,
                status: data.local_to_confirm ?? data.account_status ?? null,
                status_in_obm_account: data.status_in_obm_account ?? null,
                local_to_confirm: data.local_to_confirm ?? null,
                remarks: data.remarks,
                notify: data.notify,
                entity_id: data.entity_id,
                entity_name: data.entity_name,
                sub_category: data.sub_category,
              }
            })
          : []
      break
    case FORM_BANK_ACCOUNT_MAINTENANCE:
      requestData.acc_maintain_form_details =
        request.form_details?.length > 0
          ? request.form_details.map((data) => {
              return {
                type_of_change: data.type_of_change ?? null,
                role: data.role ?? null,
                reason: data.reason ?? null,
                emp_name: data.emp_name ? JSON.stringify(data.emp_name) : null,
                designation: data.designation ?? null,
                group: data.group ?? null,
                bank: data.group ?? null,
                currency_code: data.currency_code ?? null,
                obm_account_id: data.obm_account_id ?? null,
                account_number: data.account_number ?? null,
                acc_managed_by: data.acc_managed_by ?? null,
                online_banking_matrix:
                  data?.matrix_condition?.map(({matrix_condition, matrix_threshold}) => {
                    return {
                      matrix_condition: matrix_condition?.value ?? matrix_condition,
                      matrix_threshold: matrix_threshold?.value ?? matrix_threshold,
                    }
                  }) ?? [],
                approved_person: data.approved_person,
                manual_payment_approvers_limit: data.manual_payment_approvers_limit,
                new_bank_account_number: data.new_bank_account_number,
                new_system_admin_setup: data.new_system_admin_setup,
                reason_for_change: data.reason_for_change,
                emp_details: data.emp_details.map((value) => {
                  return {
                    designation: value.company_designation ?? '',
                    reason: value.reason ?? '',
                    fullname: value.fullname?.full_name ?? '',
                    email: value.email ?? '',
                    approve_type: value.approve_type?.value ?? '',
                  }
                }),
                bank_details: data.bank_details.map((value) => {
                  return {
                    role: value.role?.value ?? '',
                    group: value.group ?? '',
                    bank_id: value.bank_id?.value ?? '',
                    bank_code: value.bank_id?.code ?? '',
                    currency_code: value.currency_code ?? '',
                    obm_account_id: value.obm_account_id ?? '',
                    account_number: value.account_number ?? '',
                    acc_managed_by: value.acc_managed_by?.value
                      ? value.acc_managed_by.value
                      : value.acc_managed_by ?? '',
                    account_type: value.account_type?.value
                      ? value.account_type.value
                      : value.account_type ?? '',
                  }
                }),
              }
            })
          : []
      break
    case FORM_BANK_GUARANTEE:
      requestData.bg_request_form_details = [
        {
          bg_request_id: form_details.bg_request_id,
          bank_id: form_details.bank_id?.value ?? form_details.bank_id,
          type_of_facility: form_details.type_of_facility,
          type_of_guarantee: form_details.type_of_guarantee,
          previous_ticket: form_details.previous_ticket,
          reason: form_details.reason,
          beneficiary_address: form_details.beneficiary_address,
          beneficiary_contact: JSON.stringify({
            name: form_details.beneficiary_name,
            contact: form_details.beneficiary_contact,
          }),
          kyriba_bg_ref_no: form_details.kyriba_bg_ref_no,
          kyriba_previous_ref_no: form_details.kyriba_previous_ref_no,
          purpose_of_guarantee: form_details.purpose_of_guarantee,
          guarantee_amount: form_details.guarantee_amount,
          currency_code: form_details.currency_code,
          format_of_guarantee: form_details.format_of_guarantee,
          start_effective_date: form_details.start_effective_date,
          expiry_date: form_details.expiry_date,
          final_claim_date: form_details.final_claim_date,
          guarantee_required_date: form_details.guarantee_required_date,
          delivery_method: form_details.delivery_method,
          other_requirement: form_details.other_requirement,
          collateral_required: form_details.collateral_required,
          collateral_amount: form_details.collateral_amount,
          commission_charge_amount: form_details.commission_charge_amount,
          other_issuance_amount: form_details.other_issuance_amount,
          auto_renewal_applicable: form_details.auto_renewal_applicable,
        },
      ]
      break
    case FORM_DOCUMENT_SIGNING_REQUEST:
      requestData.doc_signing_bank =
        form_details?.doc_signing_bank?.value ?? form_details.doc_signing_bank
      requestData.doc_signing_purpose = form_details.doc_signing_purpose
      break
    default:
      break
  }
  return requestData
}

/**
 * To save internal request form
 * @param request
 * @return {Promise<any>}
 */
export async function saveInternalRequest(request) {
  const requestData = retrieveRequestData(request)
  const {data} = await TSApi.put('/save_ticket/', requestData)
  return data
}

/**
 * To submit and approve internal request form
 * @param request
 * @return {Promise<any>}
 */
export async function submitInternalRequest(request) {
  const requestData = retrieveRequestData(request)

  const {data} = await TSApi.put('/update_ticket/', {
    ...requestData,
    current_order: request.current_order ?? 0,
  })

  return data
}

/**
 * To reject internal request form
 * @param request
 * @return {Promise<any>}
 */
export async function rejectInternalRequest(value, request) {
  const {data} = await TSApi.put('/reject_ticket/', {
    ticket_id: request.ticket_id,
    comments: `<p> ${value.value ? encode(value.value) : 'Reject Ticket'} </p>`,
    current_order: request.current_order,
    reject_order: value.order,
    status: request.status,
    step_name: value.step_name,
  })

  return data
}

/**
 * To withdraw internal request form
 * @param request
 * @return {Promise<any>}
 */
export async function withdrawnInternalRequest(comment, request) {
  const {data} = await TSApi.put('/withdraw_ticket/', {
    ticket_id: request.ticket_id,
    comments: `<p> ${comment ? encode(comment) : 'Withdrawn Ticket'} </p>`,
    current_order: request.current_order,
  })

  return data
}

export type FetchOnlineBankingMatrixAccountType = Array<{
  acc_managed_by: string,
  account_type: string,
  bank_account_number: string,
  bank_id: string,
  bank_desc: string,
  currency_code: string,
  entity_id: string,
  entity_name: string,
  status: string,
}>

/**
 * To retrieve bank account from online bank matrix based on entity
 * @return {Promise<FetchOnlineBankingMatrixAccountType>}
 */
export async function fetchOnlineBankingMatrixAccount(
  entity: string,
  subCategory: string,
  accToXfer?: boolean
): FetchOnlineBankingMatrixAccountType {
  let filterQueryString = ''
  entity.map((arrayValue) => {
    filterQueryString = filterQueryString.concat(`&entity_id=`, arrayValue)
  })
  let apiUrl = `/obm_account/?sub_category=${subCategory}${filterQueryString}`

  if (accToXfer) {
    apiUrl += `&acc_to_trf=${accToXfer ? 'Y' : 'N'}`
  }

  const {data} = await TSApi.get(apiUrl)

  return data
}

/**
 * To retrieve the diff metadata based on the diff form
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchFormMetadata(form: string, type: string): DefaultSelectType {
  const {data} = await TSApi.get(`/metadata/?form_type=${form}&metadata_type=${type}`)

  return data
}

/**
 * To check if employee exists
 * @param employeeList
 * @return {Promise<any>}
 */
export async function checkEmployeeExists(employeeList: Array) {
  const {data} = await TSApi.put(`check_employee/`, employeeList)

  return data
}

/**
 * To retrieve the master employee list by bank account number
 * @return {Promise<FetchUserType>}
 */
export async function fetchEmployees(bankAcc): FetchUserType {
  if (!bankAcc) {
    return []
  }
  let url = `/employee/?bank_account_number=${bankAcc}`

  const {data} = await TSApi.get(url)

  return data
}

/**
 * To retrieve OBM employee
 * @param request
 * @return {Promise<any>}
 */
export async function retrieveOBMEmployee(bankId, bankAcc, entityId) {
  if (bankAcc.length === 0) {
    return []
  }

  const {data} = await TSApi.put('/obm_employee/', {
    banks: bankId,
    bank_account_number: bankAcc,
    entities: entityId,
  })

  return data
}

/**
 * To retrieve the ticket summary for previous reject list
 * @return {Promise<FetchUserType>}
 */
export async function fetchPreviousRejectList(ticketId, orderNo): FetchUserType {
  let url = `/request_ticket_summary_reject_list/?ticket_id=${ticketId}&order_number=${orderNo}`

  const {data} = await TSApi.get(url)

  return data
}

/**
 * To retrieve the ticket summary for previous steps
 * @return {Promise<FetchUserType>}
 */
export async function fetchTicketProgressIndicator(ticketId): FetchUserType {
  let url = `/request_ticket_summary_edit/?ticket_id=${ticketId}&tz=${timeZone}`

  const {data} = await TSApi.get(url)

  return data
}

/**
 * To retrieve the ticket summary history
 * @return {Promise<FetchUserType>}
 */
export async function fetchTicketHistory(ticketId): FetchUserType {
  let url = `/ticket_history/?ticket_id=${ticketId}&tz=${timeZone}`

  const {data} = await TSApi.get(url)

  return data
}

/**
 * To delete internal request form draft
 * @param request
 * @return {Promise<any>}
 */
export async function deleteDraftInternalRequest(comment, request) {
  const {data} = await TSApi.put('/delete_draft_ticket/', {
    ticket_id: request.ticket_id,
    comments: `<p> ${comment ? encode(comment) : 'Delete Ticket'} </p>`,
    current_order: request.current_order,
  })

  return data
}

/**
 * To delete entities from internal request form
 * @param request
 * @return {Promise<any>}
 */
export async function deleteEntities({removeEntityDialog, dbValue, formDetails}) {
  const {data} = await TSApi.put('/delete_entities/', {
    ticket_id: formDetails.ticket_id,
    countries_id: removeEntityDialog.type === 'country' ? dbValue : [],
    entities_id: removeEntityDialog.type === 'entity' ? dbValue : [],
  })

  return data
}

/**
 * To update ticket sub category
 * @param request
 * @return {Promise<any>}
 */
export async function updateTicketSubCategory({formDetails, sub_category_1}) {
  const {data} = await TSApi.put('/update_ticket_sub_category/', {
    ticket_id: formDetails.ticket_id,
    sub_category_1: sub_category_1,
  })

  return data
}
