import {Grid} from '@mui/material'
import {getAccess} from '_helper/_authHelper'
import {FORMATTED_APP_NAME, menuListGCR, GCR_PAGE_NAME} from '_helper/_constants'
import {MenuCard} from 'finsys-webcomponent'
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {retrievePageFunctionAccess} from '_helper/_authHelper'

export function GCR() {
  const [pages, setPages] = useState([])
  const navigate = useNavigate()
  const [functionAccess, setFunctionAccess] = useState([])

  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(GCR_PAGE_NAME, setFunctionAccess)
    }
    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }

    const access = getAccess()

    if (access) {
      const pageList = menuListGCR.filter((key, value) => {
        return access[FORMATTED_APP_NAME][key.pageName]
      })
      setPages(pageList)
    } else {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  const renderCardContent = ({name, desc, backgroundColor, cardSrc}) => {
    return (
      <MenuCard name={name} desc={desc} backgroundColor={backgroundColor} src={cardSrc} />
    )
  }

  return (
    <Grid container justifyContent={'flex-start'} spacing={6}>
      {pages.map(({name, desc, backgroundColor, cardSrc, link, redirect}, index) => (
        <Grid item xs={12} sm={12} md={6} lg={3} sx={{textAlign: 'center'}} key={index}>
          {redirect ? (
            <a
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              href={link}
              target={'_blank'}
              rel="noreferrer"
            >
              {renderCardContent({name, desc, backgroundColor, cardSrc})}
            </a>
          ) : (
            <Link to={link} style={{textDecoration: 'unset'}}>
              {renderCardContent({name, desc, backgroundColor, cardSrc})}
            </Link>
          )}
        </Grid>
      ))}
    </Grid>
  )
}
