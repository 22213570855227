import {TabContent} from '../TabContent'
import {columnDefs} from './ag-grid-constants'

export const BankRating = () => {
  return (
    <TabContent
      columnDefs={columnDefs}
      tab={'BankRating'}
      label={'Bank Rating'}
      api={'gcr_dim_bank_rating'}
      searchInput={'bank code, region code'}
    />
  )
}
