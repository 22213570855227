import {Grid, Stack} from '@mui/material'
import {submitGCROtherMapper} from 'api/GCR/query/GCRQueries'
import {Accordion, Button, TextField} from 'finsys-webcomponent'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {useNavigate} from 'react-router-dom'
import {validateForm} from '../../Entity/Create'
import {useState} from 'react'
import {useMutation} from 'react-query'

export const CreateGCROther = () => {
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [formDetails, setFormDetails] = useState({
    oth_mapping_type: '',
    code: '',
    value: '',
    error_oth_mapping_type: null,
    error_code: null,
    error_value: null,
  })

  const createGCROther = useMutation(() => submitGCROtherMapper(formDetails), {
    onSuccess: (result) => {
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          type: 'success',
          message: 'Other Mapper successfully created',
        }
      })
      navigate(`/Gcr/MetadataGcr/Other/${result.oth_mapping_id}/`)
    },
    onError: (err) => {
      console.log(err)
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
        }
      })
    },
  })

  const handleValidateForm = () => {
    const requiredFields = ['oth_mapping_type', 'code', 'value']
    validateForm(formDetails, setFormDetails, requiredFields, createGCROther)
  }

  return (
    <>
      <Accordion title="Details">
        <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Code'}
              value={formDetails.code}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  code: value.target.value,
                  error_code: null,
                })
              }
              required
              error={!!formDetails.error_code}
              helperText={formDetails.error_code}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Value'}
              value={formDetails.value}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  value: value.target.value,
                  error_value: null,
                })
              }
              required
              error={!!formDetails.error_value}
              helperText={formDetails.error_value}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Other Mapping Type'}
              value={formDetails.oth_mapping_type}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  oth_mapping_type: value.target.value,
                  error_oth_mapping_type: null,
                })
              }
              required
              error={!!formDetails.error_oth_mapping_type}
              helperText={formDetails.error_oth_mapping_type}
            />
          </Grid>
        </Grid>
      </Accordion>
      <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
        <Button label="Cancel" type="text" onClick={() => navigate('/Gcr/MetadataGcr')} />
        <Button label="Create" onClick={() => handleValidateForm()} />
      </Stack>
    </>
  )
}
