import {FormTable} from 'finsys-webcomponent'
import React from 'react'

export const TabContent = ({tab, data}) => {
  return (
    <>
      <FormTable
        columns={[
          {
            name: 'Action',
            field: 'action',
            width: '50%',
          },
          {
            name: 'Entry count',
            field: 'entry_count',
            component: (event) => {
              return <div style={{paddingLeft: 30}}>{event.value}</div>
            },
          },
        ]}
        data={[
          {action: 'Add', entry_count: data?.add ?? 0},
          {action: 'Update', entry_count: data?.update ?? 0},
          {action: 'Error', entry_count: data?.error.length ?? 0},
        ]}
      />
    </>
  )
}
