import {AgGridTable, Dialog, IconButton, SearchInput, Tabs} from 'finsys-webcomponent'
import {getPagination} from 'localstorage/PaginationSettings'
import {useEffect, useState} from 'react'
import CreateIcon from 'assets/icons/add.png'
import {Box, Button, Grid, Menu, MenuItem, Stack, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import useDataFetching from 'hoc/UseDataFetching'
import {
  exportGCRMetaDataFile,
  fetchGCRMetaDataPaginated,
  uploadGCRMetaDataFile,
} from 'api/GCR/query/GCRQueries'
import {frameworkComponents} from '../Entity/ag-grid-constants'
import {styled} from '@mui/material/styles'
import {
  Settings as SettingsIcon,
  CloudUpload as CloudUploadIcon,
  Error as ErrorIcon,
} from '@mui/icons-material'
import {useMutation} from 'react-query'
import {Entity} from 'components/GCR/ExcelUpload/Entity'
import {OtherMapper} from 'components/GCR/ExcelUpload/OtherMapper'
import {BankRating} from 'components/GCR/ExcelUpload/BankRating'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {queryClient} from 'hoc/ReactQuery'
import {getUserRole} from 'localstorage/UserRole'
import {HQ_GCT_ROLE_ID, HQ_ROLE_ID, IT_ROLE_ID} from '_helper/_constants'
import {useExcelUpload} from '../_hooks/useExcelUpload'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export const TabContent = ({columnDefs, tab, label, api, searchInput}) => {
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    page: 1,
    rowsPerPage: getPagination().rowPerPage,
    toNextPage: null,
  })
  const {
    writeErrorExcel,
    fileUploadDialog,
    setFileUploadDialog,
    uploadResultDialog,
    setUploadResultDialog,
  } = useExcelUpload()
  const [searchFilter, setSearchFilter] = useState({temp: '', actual: ''})
  const [throttle, setThrottle] = useState(null)
  const [openSettings, setOpenSettings] = useState(null)
  const [gridApi, setGridApi] = useState(null)

  const {data: gcrMetaData, loading: isGCRMetaDataLoading} = useDataFetching(
    [
      tab,
      {
        page: serverPaginationResult.page,
        rowsPerPage: serverPaginationResult.rowsPerPage,
        searchFilter: searchFilter.actual,
      },
    ],
    () =>
      fetchGCRMetaDataPaginated(
        api,
        serverPaginationResult.page,
        serverPaginationResult.rowsPerPage,
        searchFilter.actual
      )
  )

  useEffect(() => {
    if (!gcrMetaData) {
      return
    }

    setServerPaginationResult({
      ...serverPaginationResult,
      rowData: gcrMetaData.results,
      totalPages: gcrMetaData.total_pages,
      totalCount: gcrMetaData.count,
      toNextPage: gcrMetaData.next,
    })
  }, [gcrMetaData])

  const uploadExcelFile = useMutation(
    () => uploadGCRMetaDataFile(fileUploadDialog.attachment),
    {
      onSuccess: (result) => {
        setSnackBar((prevState) => {
          return {
            ...prevState,
            open: true,
            type: 'success',
            message: 'File upload successful',
          }
        })
        setFileUploadDialog({open: false, attachment: null})
        setUploadResultDialog({
          open: true,
          content: result.data,
          upload_error: result.upload_error,
        })
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => {
          return {
            ...prevState,
            open: true,
          }
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          tab,
          {
            page: serverPaginationResult.page,
            rowsPerPage: serverPaginationResult.rowsPerPage,
            searchFilter: searchFilter.actual,
          },
        ])
      },
    }
  )

  const exportMetadata = useMutation((tab) => exportGCRMetaDataFile(tab), {
    onSuccess: (result) => {
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          type: 'success',
          message: 'File export successful',
        }
      })
    },
    onError: (err) => {
      console.log(err)
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
        }
      })
    },
  })

  const handleSearchFilter = (value) => {
    setSearchFilter({...searchFilter, temp: value})
    clearTimeout(throttle)
    setThrottle(
      setTimeout(() => {
        setSearchFilter({...searchFilter, actual: value})
      }, 500)
    )
  }

  const onGridReady = async (params) => {
    setGridApi(params.api)
  }

  return (
    <>
      {uploadResultDialog.open && (
        <Dialog
          title="Bulk Data Upload Result"
          type="info"
          content={[
            <>
              <Tabs
                tabList={[
                  {
                    label: 'Entity',
                    panel: <Entity data={uploadResultDialog.content?.entity} />,
                  },
                  {
                    label: 'Other Mapper',
                    panel: (
                      <OtherMapper data={uploadResultDialog.content?.other_mapper} />
                    ),
                  },
                  {
                    label: 'Bank Rating',
                    panel: <BankRating data={uploadResultDialog.content?.bank} />,
                  },
                ]}
              />
              {uploadResultDialog.upload_error && (
                <IconButton
                  title=""
                  handleOnClick={() => writeErrorExcel(uploadResultDialog.content)}
                >
                  <ErrorIcon fontSize="small" sx={{color: '#FF8080'}} />
                  <Typography variant="caption">Download error log</Typography>
                </IconButton>
              )}
            </>,
          ]}
          buttons={[
            {
              label: 'Close',
              type: 'text',
              onClick: () => {
                setUploadResultDialog({open: false, content: {}, upload_error: false})
              },
            },
          ]}
          disableBackdropClick={true}
        />
      )}
      {fileUploadDialog.open && (
        <Dialog
          title="File Upload for Bulk Data Entry"
          content={
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography>Select file to upload</Typography>
              </Grid>
              <Grid item xs={5}>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  size="small"
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) =>
                      setFileUploadDialog({
                        ...fileUploadDialog,
                        attachment: event.target.files[0],
                      })
                    }
                    multiple
                  />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    maxWidth: '100px',
                  }}
                >
                  <Typography variant="caption">
                    {fileUploadDialog.attachment?.name ?? 'No files selected'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          }
          type="info"
          buttons={[
            {
              label: 'Cancel',
              type: 'text',
              onClick: () => {
                setFileUploadDialog({open: false, attachment: null})
              },
            },
            {
              label: 'Upload',
              type: 'primary',
              onClick: () => {
                uploadExcelFile.mutate()
              },
            },
          ]}
          disableBackdropClick={true}
        />
      )}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <SearchInput
          placeholder={`Search for ${searchInput}`}
          handleOnChange={(value) => handleSearchFilter(value)}
        />
        {[HQ_ROLE_ID, HQ_GCT_ROLE_ID, IT_ROLE_ID].includes(getUserRole().role) && (
          <>
            <IconButton
              title="Settings"
              handleOnClick={(event) => {
                setOpenSettings(event.currentTarget)
              }}
            >
              <SettingsIcon />
            </IconButton>
            {['Other', 'BankRating'].includes(tab) &&
              getUserRole().role === HQ_ROLE_ID && (
                <IconButton
                  title="Create"
                  handleOnClick={() => {
                    navigate(`/Gcr/MetadataGcr/${tab}/Create`)
                  }}
                >
                  <img src={CreateIcon} alt="Create" />
                </IconButton>
              )}
            <Menu
              anchorEl={openSettings}
              open={!!openSettings}
              style={{marginTop: '40px'}}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setOpenSettings(null)}
            >
              <MenuItem
                onClick={() => {
                  setOpenSettings(null)
                  window.open(process.env.REACT_APP_GCR_METADATA_TEMPLATE_URL, '_blank')
                }}
              >
                <Typography variant="overline">Download Excel Template</Typography>
              </MenuItem>
              {[HQ_ROLE_ID, HQ_GCT_ROLE_ID].includes(getUserRole().role) && (
                <MenuItem
                  onClick={() => {
                    setOpenSettings(null)
                    setFileUploadDialog({...fileUploadDialog, open: true})
                  }}
                >
                  <Typography variant="overline">Upload Excel</Typography>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setOpenSettings(null)
                  exportMetadata.mutate(tab)
                }}
              >
                <Typography variant="overline">{`Export ${label}`}</Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Stack>
      <AgGridTable
        onGridReady={onGridReady}
        gridApi={gridApi}
        serverPaginationResult={serverPaginationResult}
        setServerPaginationResult={setServerPaginationResult}
        page={serverPaginationResult.page}
        isLoading={isGCRMetaDataLoading}
        pageInset={215}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        suppressRowTransform={true}
        onColumnResized={null}
        onColumnVisible={null}
      />
    </>
  )
}
