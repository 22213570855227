import {Grid, Stack} from '@mui/material'
import {Accordion, Autocomplete, Button, Loading, TextField} from 'finsys-webcomponent'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {useState} from 'react'
import {useMutation} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {validateForm} from '../../Entity/Create'
import {fetchGCRBankCodeBankName, submitGCRBankRating} from 'api/GCR/query/GCRQueries'
import {getUserRole} from 'localstorage/UserRole'
import {HQ_ROLE_ID} from '_helper/_constants'
import useDataFetching from 'hoc/UseDataFetching'

export const CreateGCRBankRating = () => {
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [formDetails, setFormDetails] = useState({
    bank_info: {code: '', description: ''},
    bank_code: '',
    region_code: '',
    one_yr_cds: null,
    avg_sum_of_rating: '',
    rating_type: '',
    avg_sum_of_rating_snp_equivalent: '',
    last_update_date: '',
    last_updated_by: '',
    error_bank_code: null,
    error_region_code: null,
    error_one_yr_cds: null,
  })

  const {data: bankInfo, loading: isBankInfoLoading} = useDataFetching(
    `gcr-bank-code-bank-name`,
    () => fetchGCRBankCodeBankName()
  )

  const createGCRBankRating = useMutation(() => submitGCRBankRating(formDetails), {
    onSuccess: (result) => {
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          type: 'success',
          message: 'Bank Rating successfully created',
        }
      })
      navigate(`/Gcr/MetadataGcr/BankRating/${result.bank_rating_id}/`)
    },
    onError: (err) => {
      console.log(err)
      setSnackBar((prevState) => {
        return {
          ...prevState,
          open: true,
          message: err.data.status,
        }
      })
    },
  })

  const handleValidateForm = () => {
    const requiredFields = ['bank_code', 'region_code']

    if (
      isNaN(Number(formDetails.one_yr_cds)) &&
      !['N.A.', ''].includes(formDetails.one_yr_cds?.trim())
    ) {
      setFormDetails((prevState) => {
        return {
          ...prevState,
          error_one_yr_cds: "Enter a valid number or 'N.A.'",
        }
      })
      return
    }

    validateForm(formDetails, setFormDetails, requiredFields, createGCRBankRating)
  }

  const isAccessRestricted = () => {
    return getUserRole().role !== HQ_ROLE_ID
  }

  if (isBankInfoLoading) {
    return <Loading open={true} />
  }

  return (
    <>
      <Accordion title="Details">
        <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              label={'Bank Info'}
              value={formDetails.bank_info}
              options={bankInfo}
              getOptionLabel={(option) =>
                option.code ? `${option.description} (${option.code})` : ''
              }
              handleOnChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  bank_info: value,
                  bank_code: value?.code,
                  error_bank_code: null,
                })
              }
              textFieldProps={{
                required: true,
                error: !!formDetails.error_bank_code,
                helperText: formDetails.error_bank_code,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Region Code'}
              value={formDetails.region_code}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  region_code: value.target.value,
                  error_region_code: null,
                })
              }
              required={true}
              error={!!formDetails.error_region_code}
              helperText={formDetails.error_region_code}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'One Year CDS'}
              value={formDetails.one_yr_cds}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  one_yr_cds: value.target.value,
                  error_one_yr_cds: null,
                })
              }
              error={!!formDetails.error_one_yr_cds}
              helperText={formDetails.error_one_yr_cds}
              disabled={isAccessRestricted()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Average/ Summary of Rating '}
              value={formDetails.avg_sum_of_rating}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  avg_sum_of_rating: value.target.value,
                })
              }
              disabled={isAccessRestricted()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Rating Type'}
              value={formDetails.rating_type}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  rating_type: value.target.value,
                })
              }
              disabled={isAccessRestricted()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Average/Summary of Rating (S&P equivalent)'}
              value={formDetails.avg_sum_of_rating_snp_equivalent}
              onChange={(value) =>
                setFormDetails({
                  ...formDetails,
                  avg_sum_of_rating_snp_equivalent: value.target.value,
                })
              }
              disabled={isAccessRestricted()}
            />
          </Grid>
        </Grid>
      </Accordion>
      <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
        <Button label="Cancel" type="text" onClick={() => navigate('/Gcr/MetadataGcr')} />
        <Button label="Create" onClick={() => handleValidateForm()} />
      </Stack>
    </>
  )
}
